





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'LandingPreview',
})
export default class extends Vue {
    @Prop({ required: true, default: false }) private showPreview!: boolean
    @Prop({ required: false, default: '' }) private content?: string
    private device = 'desktop'
    $refs: any

    @Watch('content', { deep: true, immediate: true })
    setIframeContent (val: string) {
        if (!val) return
        const iframeEl = this.$refs.iframeLive
        if (!iframeEl) return
        iframeEl.contentDocument.write(val)
        this.sanitazeLink(iframeEl)
        iframeEl.contentDocument.close()
    }

    mounted () {
        if (!this.content) return
        this.setIframeContent(this.content)
    }

    private sanitazeLink (iframeEl: any) {
        this.setLinkTargetBlank(iframeEl, 'a[href]', 'target')
        this.sanitazeLinkProtocol(iframeEl, 'a[href]')
        this.setLinkTargetBlank(iframeEl, 'img[data-href]', 'data-target')
    }

    private setLinkTargetBlank (iframeEl: any, tag: string, target: string) {
        const selectors = iframeEl.contentDocument.querySelectorAll(tag)
        selectors.forEach(function (selector: any) {
            selector.setAttribute(target, '_blank')
        })
    }

    private sanitazeLinkProtocol (iframeEl: any, tag: string) {
        const selectors = iframeEl.contentDocument.querySelectorAll(tag)
        selectors.forEach((selector: any) => {
            let href = selector.getAttribute('href')
            if (!this.isValidHttpUrl(href)) {
                href = 'https://' + href
            }
            selector.setAttribute('href', href)
        })
    }

    private isValidHttpUrl (str: string) {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const url = new URL(str)
            return true
        } catch (e) {
            return false
        }
    }
}
