import {
    commandNameOpenAssets,
} from '../consts'

export default (editor: any) => {
    const cm = editor.Commands
    let globalOpt: any = null

    const addImageToItem = (e: any) => {
        // if (!this.customGalleryTarget) return
        if (!globalOpt) return
        if (globalOpt.target) globalOpt.target.set('src', e)
        if (globalOpt.change) {
            globalOpt.change({ event: { detail: { url: e } }, complete: true })
        }
    }

    cm.add(commandNameOpenAssets, {
        run: (editor: any, sender: any, opts: any = {}) => {
            globalOpt = opts
            if (!globalOpt.target) globalOpt.target = editor.getSelected()

            const src = globalOpt.target.get('src')

            window.openVsMediaLibrary({
                url: src,
                mode: 'selection',
                allowedTypes: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/icon,image/vnd.microsoft.icon,image/svg+xml',
            }).then(addImageToItem).catch((e: any) => { console.log(e) })
        },
    })
}
