
























































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { GrapesVueComponentMixin } from './../mixins/grapes-vue-component.mixin'
import VsInlineInputEdit from '@/components/VsInlineInputEdit/index.vue'
import PageNameModal from '@/modules/landingpages/components/PageNameModal/Index.vue'
import { UserModule } from '@/store/modules/user'
@Component({
    name: 'GjTopBar',
    components: {
        VsInlineInputEdit,
        PageNameModal,
    },
})
export default class extends Mixins(GrapesVueComponentMixin) {
    @Prop({ required: true, default: null }) page!: any
    @Prop({ required: false, default: false }) isDirty!: boolean
    @Prop({ required: false, default: false }) loading!: boolean
    private showPangeNameModal = false
    private device = 'Desktop'
    private isActiveSwBtn = true
    $refs: any

    @Watch('device')
    private setDevice (val: string) {
        if (!this.editor) return
        this.editor.setDevice(val)
        const currentSelected = this.editor.getSelected()
        if (!currentSelected) return
        this.editor
            .selectToggle(currentSelected)
            // .select(currentSelected) // lo riseleziona troppo velocemente
    }

    private toggleSw () {
        if (this.isActiveButton('sw-visibility')) {
            this.editor.Commands.stop('sw-visibility')
            this.isActiveSwBtn = false
        } else {
            this.editor.Commands.run('sw-visibility')
            this.isActiveSwBtn = true
        }
    }

    private showCode () {
        if (!this.editor) return
        this.editor.Commands.run('export-template')
    }

    private isActiveButton (commandId: string) {
        if (!this.editor) return false
        const isActive = this.editor.Commands.isActive(commandId)
        return isActive
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canExportHtml () {
        return this.userRules.landingpage.export
    }

    private async savePageName (saveButton?: string) {
        const pageName = await this.$refs.pageNameModal.open(this.page.name, saveButton)
        this.changePageName(pageName)
    }

    private async openPageNameModal () {
        try {
            await this.savePageName()
        } catch (e) {}
        this.$refs.pageNameModal.close()
    }

    private changePageName (e: any) {
        this.$emit('edit-page-name', e)
    }

    private async save (saveEvent: string) {
        if (!this.page.name) {
            try {
                await this.savePageName('Salva e pubblica')
                this.$refs.pageNameModal.close()
            } catch (e) {
                this.$refs.pageNameModal.close()
                return
            }
        }
        this.$emit(saveEvent)
    }
}
