import {
    typeLeadplus,
} from '../consts'

export default function (editor: any, opt: any = {}): any {
    const { blockLeadplus, blockLabel, enabled, blockTitle } = opt
    const bm = editor.BlockManager

    const svgForm = `<svg class="gjs-block-svg" id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><path class="cls-1" d="M57.79,13.9H10.38V51.51H41.73V56L45,53.69l2.07,5.7,2.33-.86-2.11-5.7,4-.4-.81-.92h7.36Zm-3.66,34H47.24l-5.51-6.32v6.32H14V17.55h40.1Z"/><rect class="cls-1" x="17.29" y="22.79" width="33.43" height="6.36"/><rect class="cls-1" x="17.29" y="32.86" width="33.43" height="6.36"/></svg>`
    bm.add(typeLeadplus, {
        label: blockTitle,
        render: () => {
            return `${svgForm}<div class="gjs-block-label">${blockLabel}</div>`
        },
        category: 'element',
        disable: !enabled,
        activate: true,
        select: true,
        content: `
        <div data-gjs-type="${typeLeadplus}">
            <div class="fogForm">
                <div data-gjs-type="fdlp-placeholder">
                    ${svgForm}
                </div>
            </div>
        </div>
        <style>
            .${typeLeadplus} .gjs-block-svg {
                text-align: center;
                width: 100%;
                height: 100px;
                padding-top: 15px;
                background: #f5f5f5;
                padding-bottom: 15px;
            }

            .${typeLeadplus} .gjs-block-svg * {
                fill: #ddd;
            }
            .${typeLeadplus} div[data-form] .gjs-block-svg * {
                fill: #1976d2;
            }
        </style>
    `,
        ...blockLeadplus,
    })
}
