export const grapesjsCustomThemeOptions = {
    // modalImportTitle: 'Import Template',
    // modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
    // modalImportContent (editor: any) {
    //     return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
    // },
    customStyleManager: [
        {
            name: 'Styles',
            buildProps: [
                'text-align',
                'align-items',
                'color',
                'font-family',
                'background-color',
                'background-bg',
                // 'box-shadow',
                'border',
                'width',
                'height',
                'ombr',
                'margin-top',
                'margin-left',
                'margin-right',
                'margin-bottom',
                'padding-top',
                'padding-left',
                'padding-right',
                'padding-bottom',
            ],
            properties: [
                {
                    id: 'ombr',
                    name: 'Ombra esterna',
                    property: 'box-shadow',
                    type: 'composite',
                    properties: [
                        {
                            name: 'Orizzontale',
                            property: 'box-shadow-h',
                            type: 'integer',
                            defaults: 0,
                            units: ['px'],
                            unit: 'px',
                        },
                        {
                            name: 'Verticale',
                            property: 'box-shadow-v',
                            type: 'integer',
                            defaults: 0,
                            units: ['px'],
                            unit: 'px',
                        },
                        {
                            name: 'Sfocatura',
                            property: 'box-shadow-blur',
                            type: 'integer',
                            defaults: 0,
                            units: ['px'],
                            unit: 'px',
                        },
                        {
                            name: 'Diffusione',
                            property: 'box-shadow-spread',
                            type: 'integer',
                            defaults: 0,
                            units: ['px'],
                            unit: 'px',
                        },
                        {
                            name: 'Colore',
                            property: 'box-shadow-color',
                            type: 'color',
                            defaults: '#000000',
                        },
                        // {
                        //     name: 'Shadow type',
                        //     property: 'box-shadow-type',
                        //     type: 'select',
                        //     list: [{
                        //         value: '',
                        //         name: ''
                        //     }]
                        // },
                    ],
                },
                {
                    name: 'Allineamento colonne',
                    property: 'align-items',
                    type: 'radio',
                    defaults: 'stretch',
                    list: [
                        {
                            value: 'flex-start',
                            title: 'Alto',
                            className: 'fa mdi mdi-align-vertical-top',
                        },
                        {
                            value: 'flex-end',
                            title: 'Basso',
                            className: 'fa mdi mdi-align-vertical-bottom',
                        },
                        {
                            value: 'center',
                            title: 'Centrato',
                            className: 'fa mdi mdi-align-vertical-center',
                        },
                        {
                            value: 'stretch',
                            title: 'Adatta',
                            className: 'fa mdi mdi-arrow-expand-vertical',
                        },
                    ],
                },
                { name: 'Colore del testo', property: 'color' },
                {
                    name: 'Font',
                    property: 'font-family',
                    list: [
                        {
                            value: 'Arial Black,Arial Bold,Gadget,sans-serif',
                            name: 'Arial Black',
                        },
                        {
                            value: 'Book Antiqua,Palatino,Palatino Linotype,Palatino LT STD,Georgia,serif',
                            name: 'Book Antiqua',
                        },
                        {
                            value: 'Comic Sans MS, Comic Sans, cursive, sans-serif',
                            name: 'Comic Sans MS',
                        },
                        {
                            value: 'Courier New, Courier, monospace',
                            name: 'Courier New',
                        },
                        {
                            value: 'Georgia,Times,Times New Roman,serif',
                            name: 'Georgia',
                        },
                        {
                            value: 'Helvetica Neue,Helvetica,Arial,sans-serif',
                            name: 'Helvetica',
                        },
                        {
                            value: 'Impact,Haettenschweiler,Franklin Gothic Bold,Charcoal,Helvetica Inserat,Bitstream Vera Sans Bold,Arial Black,sans serif',
                            name: 'Impact',
                        },
                        {
                            value: 'Lato, sans-serif',
                            name: 'Lato',
                        },
                        {
                            value: 'Lobster Two, cursive',
                            name: 'Lobster Two',
                        },
                        {
                            value: 'Montserrat, sans- serif',
                            name: 'Montserrat',
                        },
                        {
                            value: 'Pacifico, cursive',
                            name: 'Pacifico',
                        },
                        {
                            value: 'Raleway, sans- serif',
                            name: 'Raleway',
                        },
                        {
                            value: 'Rubik, sans- serif',
                            name: 'Rubik',
                        },
                        {
                            value: 'Tahoma,Verdana,Segoe,sans-serif',
                            name: 'Tahoma',
                        },
                        {
                            value: 'Times New Roman, Times, serif',
                            name: 'Times New Roman',
                        },
                        {
                            value: 'Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif',
                            name: 'Trebuchet MS',
                        },
                        {
                            value: 'Verdana,Geneva,sans-serif',
                            name: 'Verdana',
                        },
                        {
                            value: 'Lucida Sans Unicode,Lucida Sans,Geneva,Verdana,sans-serif',
                            name: 'Lucida Sans Unicode',
                        },
                    ],
                },
                { name: 'Colore di sfondo', property: 'background-color' },
                {
                    id: 'background-bg',
                    name: 'Sfondo',
                    property: 'background',
                    type: 'bg',
                },
                // {
                //     property: 'box-shadow',
                //     properties: [
                //         { name: 'X position', property: 'box-shadow-h' },
                //         { name: 'Y position', property: 'box-shadow-v' },
                //         { name: 'Blur', property: 'box-shadow-blur' },
                //         { name: 'Spread', property: 'box-shadow-spread' },
                //         { name: 'Color', property: 'box-shadow-color' },
                //         { name: 'Shadow type', property: 'box-shadow-type' },
                //     ],
                // },
                {
                    name: 'Bordo',
                    property: 'border',
                    properties: [
                        {
                            name: 'Spessore',
                            property: 'border-width',
                        },
                        {
                            name: 'Tipologia',
                            property: 'border-style',
                            list: [
                                {
                                    value: 'none',
                                    name: 'Nessuno',
                                },
                                {
                                    value: 'solid',
                                    name: 'Linea',
                                },
                                {
                                    value: 'dotted',
                                    name: 'Puntinato',
                                },
                                {
                                    value: 'dashed',
                                    name: 'Righettato',
                                },
                                {
                                    value: 'double',
                                    name: 'Doppio',
                                },
                                {
                                    value: 'groove',
                                    name: 'Scanalatura',
                                },
                                {
                                    value: 'ridge',
                                    name: 'Cresta',
                                },
                                {
                                    value: 'inset',
                                    name: 'Bassorilievo',
                                },
                                {
                                    value: 'outset',
                                    name: 'Altorilievo',
                                },
                            ],
                        },
                        { name: 'Colore', property: 'border-color' },
                    ],
                },
                {
                    property: 'width',
                    name: 'Larghezza',
                },
                {
                    property: 'height',
                    name: 'Altezza',
                },
                {
                    name: 'Allineamento contenuto',
                    property: 'text-align',
                    type: 'radio',
                    defaults: 'left',
                    list: [
                        { value: 'left', name: 'Left', className: 'fa fa-align-left' },
                        { value: 'center', name: 'Center', className: 'fa fa-align-center' },
                        { value: 'right', name: 'Right', className: 'fa fa-align-right' },
                        { value: 'justify', name: 'Justify', className: 'fa fa-align-justify' },
                    ],
                },
                { name: 'Margin Top', property: 'margin-top', type: 'integer' },
                { name: 'Margin Left', property: 'margin-left', type: 'integer' },
                { name: 'Margin Right', property: 'margin-right', type: 'integer' },
                { name: 'Margin Bottom', property: 'margin-bottom', type: 'integer' },
                { name: 'Padding Top', property: 'padding-top', type: 'integer' },
                { name: 'Padding Left', property: 'padding-left', type: 'integer' },
                { name: 'Padding Right', property: 'padding-right', type: 'integer' },
                { name: 'Padding Bottom', property: 'padding-bottom', type: 'integer' },
            ],
        },
    ],
}
