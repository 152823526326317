import grapesjs from 'grapesjs'
import pluginExport from 'grapesjs-plugin-export'
import pluginLeadplusWithoutGroups from './../grapesjs-leadplus-without-groups'
import pluginCustomGallery from './../grapesjs-custom-gallery'
import commands from './commands'
import blocks from './blocks'
import components from './components'
import panels from './panels'
import styles from './styles'
export { hasBgImageClass } from './styles'

export default grapesjs.plugins.add('gjs-custom-theme', (editor: any, opts: any = {}) => {
    const config: any = opts
    const defaults: any = {
        grapesjsCustomThemeOptions: {
            modalImportTitle: 'Import',

            // Modal import button text
            modalImportButton: 'Import',

            // Import description inside import modal
            modalImportLabel: '',

            blocks: [
                'image',
                'text',
                'fdlp-textimage',
                'fdlp-button',
                'fdlp-row',
                'fdlp-col',
                'fdlp-link',
                'fdlp-fog-embedded',
                'fdlp-header',
                'fdlp-footer',
                'video',
                'map',
                'fdlp-quote',
                'fdlp-countdown',
            ],

            blocksBasicOpts: {
                flexGrid: 0,
                stylePrefix: 'gjs-',
                addBasicStyle: true,
                category: 'Elementi Base',
                labelText: 'TESTO',
                labelLink: 'LINK',
                labelImage: 'IMMAGINE',
                labelTextImage: 'IMG + TESTO',
                labelButton: 'BOTTONE',
                labelRow: 'BOX',
                labelColumn: 'COLONNA',
                labelHeader: 'HEADER',
                labelFooter: 'FOOTER',
                labelVideo: 'VIDEO',
                labelMap: 'MAPPA',
                labelQuote: 'CITAZIONE',
                labelCountdown: 'COUNTDOWN',
            },
            // Default content to setup on import model open.
            // Could also be a function with a dynamic content return (must be a string)
            // eg. modalImportContent: editor => editor.getHtml(),
            modalImportContent: '',

            // Code viewer (eg. CodeMirror) options
            importViewerOptions: {},

            // Confirm text before cleaning the canvas
            textCleanCanvas: 'Are you sure to clean the canvas?',

            // Show the Style Manager on component change
            showStylesOnChange: 1,

            // Text for General sector in Style Manager
            textGeneral: 'General',

            // Text for Layout sector in Style Manager
            textLayout: 'Layout',

            // Text for Typography sector in Style Manager
            textTypography: 'Typography',

            // Text for Decorations sector in Style Manager
            textDecorations: 'Decorations',

            // Text for Extra sector in Style Manager
            textExtra: 'Extra',

            // Use custom set of sectors for the Style Manager
            customStyleManager: [],

            // `grapesjs-plugin-export` plugin options
            // By setting this option to `false` will avoid loading the plugin

            // `grapesjs-aviary` plugin options, disabled by default
            // Aviary library should be included manually
            // By setting this option to `false` will avoid loading the plugin
            aviaryOpts: 0,

            // `grapesjs-plugin-filestack` plugin options, disabled by default
            // Filestack library should be included manually
            // By setting this option to `false` will avoid loading the plugin
            filestackOpts: 0,
        },

        grapesjsPluginExportOptions: {},

        // setting to false avoid import the plugin
        grapesjsLeadplusOptions: {},
    }

    // // Load grapesjsCustomThemeOptions defaults (defaults of suboptions like leadplus are into theirs folder index)
    for (const name in defaults) {
        if (name === 'grapesjsCustomThemeOptions') {
            if (config.grapesjsCustomThemeOptions) {
                for (const name in defaults.grapesjsCustomThemeOptions) {
                    if (!(name in config.grapesjsCustomThemeOptions)) {
                        config.grapesjsCustomThemeOptions[name] = defaults.grapesjsCustomThemeOptions[name]
                    }
                }
            } else {
                config.grapesjsCustomThemeOptions = defaults.grapesjsCustomThemeOptions
            }
        } else {
            if (!(name in config)) {
                config[name] = defaults[name]
            }
        }
    }

    const {
        grapesjsCustomThemeOptions,
        grapesjsPluginExportOptions,
        grapesjsLeadplusOptions,
    } = config

    // // Load components (types)
    components(editor, grapesjsCustomThemeOptions)

    // // Load blocks
    blocks(editor, grapesjsCustomThemeOptions)

    // // Load commands
    commands(editor, grapesjsCustomThemeOptions)

    // // Load panels
    panels(editor, grapesjsCustomThemeOptions)

    // // Load styles
    styles(editor, grapesjsCustomThemeOptions)

    // // Load plugins conditions
    pluginCustomGallery(editor)
    grapesjsLeadplusOptions && (grapesjsCustomThemeOptions.blocks.indexOf('fdlp-fog-embedded') > -1) && pluginLeadplusWithoutGroups(editor, grapesjsLeadplusOptions)
    grapesjsPluginExportOptions && pluginExport(editor, grapesjsPluginExportOptions)
})
