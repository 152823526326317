

















































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import grapesjs from 'grapesjs'
import html2canvas from 'html2canvas'
import grapesjsCustomTheme from './plugins/grapesjs-custom-theme'
import { grapesjsCustomThemeOptions } from './options/grapesjsCustomThemeOptions'
import grapejsParserPostcss from 'grapesjs-parser-postcss'
import { LandingpageOptionsModule } from '@/store/modules/landingpageoptions'
import { previewLive } from '@/api/landingpage/pages'
// import grapesjsPluginCkeditor from './plugins/ckeditorPlugin'
import LeadplusModalV2 from './grapes-vue-components/LeadplusModalV2/index.vue'
import LandingPreview from '@/modules/landingpages/components/LandingPreview/Index.vue'
import { camelCase } from 'lodash'
import GjTopBar from './grapes-vue-components/GjTopBar/Index.vue'
// import grapessjCustomCode from 'grapesjs-custom-code'
import TipTapContent from '@/modules/landingpages/components/TipTapContent/index.vue'
import FdlpWrapperOptions from './grapes-vue-components/options/FdlpWrapperOptions/Index.vue'
// import FdlpColOptions from './grapes-vue-components/options/FdlpColOptions/Index.vue'
import ImageOptions from './grapes-vue-components/options/ImageOptions/Index.vue'
import VideoOptions from './grapes-vue-components/options/VideoOptions/Index.vue'
import FdlpTextimageOptions from './grapes-vue-components/options/FdlpTextimageOptions/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'GrapejsEditor',
    components: {
        LandingPreview,
        GjTopBar,
        TipTapContent,
        FdlpWrapperOptions,
        // FdlpColOptions,
        ImageOptions,
        FdlpTextimageOptions,
        VideoOptions,
        LeadplusModalV2,
        VsConfirm,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) private page!: any
    @Prop({ required: true, default: null }) private project?: any

    private editor: any = null
    private customGalleryTargetOpts: any = null
    private leadplusTarget: any = null
    private leadplusForm: any = null
    private showPreview = false
    private showOptions = false
    private openLeftTab = 'blocks'
    private openTab = 'styles'
    private settingsOpen = false
    private initCss: any = null
    private initHtml: any = null
    private previewContent = ''
    private device = 'desktop'
    private textEditorData = ''
    private showTextEditor = false
    private toolsBarOpen = true
    private currentState = ''
    private currentSelected: any = null
    private showState = false
    private leadplusFormsLoading = false
    private loading = false
    private isDirty = false
    private isLeadplusFormEmbeddedEnabled = false
    $el!: any
    $refs!: {
        leadplusModalV2: LeadplusModalV2
        exitConfirm: VsConfirm
        tiptapElm: TipTapContent
    }

    mounted (): void {
        if (!this.editor) this.initEditor()
    }

    private defaultCss = ``

    private defaulthtml = `
        <div class="container" data-gjs-type="fdlp-wrapper"></div>
    `

    private beforeDestroy () {
        if (!this.editor) return
        try {
            this.editor.destroy()
        } catch {}
    }

    private async initEditor () {
        this.editor = grapesjs.init({
            container: '#gjs',
            canvas: {
                styles: [
                    'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css',
                    'https://fonts.googleapis.com/css2?family=Lato&family=Lobster+Two&family=Montserrat&family=Pacifico&family=Raleway&family=Rubik&display=swap',
                    this.baseUrl + '/public/base.css',
                ],
            },
            styleManager: {
                appendTo: '#styles',
                clearProperties: 1,
            },
            traitManager: {
                appendTo: '#traits',
            },
            layerManager: {
                appendTo: '#layers',
            },
            devicePreviewMode: 0,
            autorender: 0,
            cssIcons: '',
            allowScripts: 1,
            avoidInlineStyle: 1,
            noticeOnUnload: false,
            components: this.project.components && this.project.components.length > 0 ? this.project.components : this.project.html || this.defaulthtml,
            style: this.project.style && this.project.style.length > 0 ? this.project.style : this.project.css || this.defaultCss,
            plugins: [
                (editor: any) => grapejsParserPostcss(editor),
                (editor: any) => grapesjsCustomTheme(editor, {
                    grapesjsCustomThemeOptions: {
                        ...grapesjsCustomThemeOptions,
                        openTextEditorFunction: this.resetTipTapEditorContent,
                    },
                    // set to false to disable leadplus
                    grapesjsLeadplusOptions: {
                        enabled: true,
                        openModalFunction: this.$refs.leadplusModalV2.openModal,
                        leadplusRenderScript: `${this.leadplusUrl}/render-without-groups/app.js`,
                        blockLabel: 'FORM E POPUP',
                        pageId: this.page._id,
                        blockTitle: 'FORM E POPUP',
                    },
                }),
            ],
            storageManager: { type: null },
            height: '100%',
            showOffsets: 1,
            assetManager: {
                disableUpload: true,
            },
        })
        /* renderizzo i blocchi dove voglio e come voglio */
        const blocks = this.editor.BlockManager.getAll()
        const layoutBlocks = this.editor.BlockManager.render(blocks.models.filter((el: any) => el.attributes.category === 'layout'), { external: true, ignoreCategories: true })
        const elementBlocks = this.editor.BlockManager.render(blocks.models.filter((el: any) => el.attributes.category === 'element'), { external: true, ignoreCategories: true })
        const blElm = document.getElementById('blocks-layout')
        if (blElm) blElm.appendChild(layoutBlocks)
        const bElm = document.getElementById('blocks-element')
        if (bElm) bElm.appendChild(elementBlocks)

        this.editor.on('component:selected', (el: any) => {
            this.$el.click()
            this.editor.SelectorManager.setState('')
            this.currentSelected = el
            this.showOptions = this.elementHasOptions(el)
            this.showState = this.elementHasState(el)
            // this.$refs.tiptapElm.resetContent('')
            this.showTextEditor = el.get('showCustomTextEditor')
            this.openTab = this.elementOpenAutomaticOptions(el) ? 'options' : 'styles'
        })
        this.editor.on('component:deselected', () => {
            this.currentSelected = null
        })

        this.editor.Keymaps.remove('core:component-delete')
        this.editor.Keymaps.remove('core:component-exit')
        this.editor.Keymaps.remove('core:component-next')
        this.editor.Keymaps.remove('core:component-prev')

        this.editor.on('selector:state', (val: any) => {
            this.currentState = val
        })

        /* trick per rimuovere il placeholder dei blocchi */
        this.editor.on('component:add', (model: any) => {
            if (model && model.get('type') === 'fdlp-placeholder') return
            const parent = model.parent()
            const parentId = parent.getId()
            this.removeParentPlaceholder(parentId)
        })
        this.editor.on('component:drag:end', ({ target, parent }: any) => {
            if (target && target.get('type') === 'fdlp-placeholder') return
            const parentId = parent.getId()
            this.removeParentPlaceholder(parentId)
        })

        this.editor.on('undo', () => {
            const selected = this.editor.getSelected()
            this.setElementContentIntoTextEditor(selected)
        })

        this.editor.on('redo', () => {
            const selected = this.editor.getSelected()
            this.setElementContentIntoTextEditor(selected)
        })

        this.editor.on('load', () => {
            this.editor.Commands.run('sw-visibility')
            this.editor.select(this.editor.getWrapper())
            this.editor.UndoManager.clear()
        })

        this.editor.on('change:changesCount', () => {
            this.isDirty = true
        })

        const traitInputAttr = { placeholder: 'es. Inserisci il tuo testo qui' }
        this.editor.I18n.addMessages({
            en: {
                // styleManager: {
                //     properties: {
                //         'box-shadow': 'Obreggiatura',
                //         'box-shadow-h': 'Allineamento asse y',
                //         'box-shadow-v': 'Allineamento asse x',
                //         'box-shadow-blur': 'Sfocatura',
                //         'box-shadow-spread': 'Diffusione',
                //         'box-shadow-color': 'Colore',
                //     },
                // },
                traitManager: {
                    empty: 'Nessun tratto da editare',
                    traits: {
                        // The core library generates the name by their `name` property
                        labels: {
                            id: 'ID',
                            alt: 'Testo Alternativo',
                            title: 'Titolo',
                            href: 'Link',
                            address: 'Indirizzo',
                            mapType: 'Tipologia',
                        },
                        attributes: {
                            id: traitInputAttr,
                            alt: traitInputAttr,
                            title: { placeholder: ' ' },
                            address: { placeholder: 'Es. Corso San Martino 1' },
                            mapType: { placeholder: ' ' },
                            zoom: { placeholder: ' ' },
                            href: { placeholder: 'es. https://google.com' },
                        },
                        options: {
                            mapType: {
                                q: 'Predefinita',
                            },
                            target: {
                                _blank: 'Nuova Finestra',
                            },
                        },
                    },
                },
            },
        })

        this.editor.render()
    }

    get leadplusUrl () {
        return AppModule.consoleConf?.leadplusHost
    }

    get currentSelectedOptions () {
        if (!this.currentSelected) return null
        const selectedOptionComponentName = this.currentSelected.get('type') + '-options'
        if (!this.isLoadedComponents(selectedOptionComponentName)) return null
        return selectedOptionComponentName
    }

    get baseUrl () {
        return LandingpageOptionsModule.baseUrl
    }

    private resetTipTapEditorContent (val: string) {
        if (this.$refs.tiptapElm) this.$refs.tiptapElm.resetContent(val)
    }

    private isLoadedComponents (componentName: string) {
        const components = this.$options.components
        for (const key in components) {
            if (camelCase(componentName) === camelCase(key)) return true
        }
        return false
    }

    private removeParentPlaceholder (parentId: string) {
        const parentPlaceholder = this.editor.DomComponents.getWrapper().find(`#${parentId} > div[data-gjs-type="fdlp-placeholder"]`)[0]
        if (parentPlaceholder) {
            parentPlaceholder.remove()
        }
    }

    private async saveEdits (saveEvent: string) {
        if (this.loading) return
        this.loading = true
        const previewImage = await this.getScreenshot()
        this.$emit(saveEvent, {
            stopLoading: this.stopLoading,
            project: {
                html: this.editor.getHtml(),
                css: this.editor.getCss(),
                components: this.editor.getComponents(),
                style: this.editor.getStyle(),
                previewImage,
            },
        })
    }

    private stopLoading () {
        this.loading = false
    }

    private async previewLive () {
        try {
            const data = {
                pageId: this.page._id,
                html: this.editor.getHtml(),
                css: this.editor.getCss(),
            }

            const res = await previewLive(data)
            this.previewContent = res.data
        } catch (e) {
            this.previewContent = ''
            console.log(e)
        }
    }

    private async getScreenshot () {
        const iframeEl: any = document.getElementById('screenshot-iframe')
        if (!iframeEl) return
        const data = {
            pageId: this.page._id,
            html: this.editor.getHtml(),
            css: this.editor.getCss(),
        }
        const res = await previewLive(data)
        const previewCont = res.data
        iframeEl.contentDocument.write(previewCont.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ''))
        iframeEl.contentDocument.close()
        const body = iframeEl.contentWindow.document.getElementsByTagName('BODY')[0]

        await this.parseMapBoxes(body)
        await this.parseVideoBoxes(body)

        const canvas = await html2canvas(body, {
            windowWidth: 1024,
            windowHeight: 1140,
            width: 1024,
            height: 1140,
            proxy: '/app/userapi/users/screenshot-images-proxy',
        })
        return canvas.toDataURL('image/webp', 0.1)
    }

    timeout (ms: any) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async sleep (fn: any, ...args: any) {
        await this.timeout(3000)
        return fn(...args)
    }

    private async parseVideoBoxes (body: any) {
        const videoBoxes = body.querySelectorAll('.videoWrapper iframe, .videoWrapper video')
        if (!videoBoxes || videoBoxes.length === 0) return
        const imageUrl = `${this.baseUrl}/public/video-placeholder.jpg`
        for (const video of videoBoxes) {
            await this.setElementBackgroundImagePlaceholder(video, imageUrl, `#000000`, 'cover')
        }
    }

    private async parseMapBoxes (body: any) {
        const mapIframes = body.querySelectorAll('iframe.map-iframe')
        if (!mapIframes || mapIframes.length === 0) return
        const imageUrl = `${this.baseUrl}/public/map-placeholder.jpg`
        for (const map of mapIframes) {
            await this.setElementBackgroundImagePlaceholder(map, imageUrl, '#3b97e3', 'cover')
        }
    }

    private async setElementBackgroundImagePlaceholder (el: any, placeholderImage: string, background: string, size: string) {
        el.style.background = background
        el.style.backgroundImage = `url('${placeholderImage}')`
        el.style.backgroundSize = size
        el.style.backgroundPosition = 'center center'
        el.style.backgroundRepeat = 'no-repeat'
    }

    private async runEditorPreview () {
        this.showPreview = true
        try {
            await this.previewLive()
        } catch (error) {
            this.showPreview = false
        }
    }

    private closePreviewDialog () {
        this.previewContent = ''
        this.showPreview = false
    }

    private async returnBack () {
        let close = true
        if (this.isDirty) {
            try {
                await this.$refs.exitConfirm.openConfirm()
            } catch (e) {
                close = false
            }
        }
        if (close) {
            this.$emit('return-back')
        }
    }

    private textEditorOnInput (e: any) {
        const selectedElm = this.editor.getSelected()
        if (selectedElm) {
            selectedElm.set('content', e)
        }
        // per rimuovere tutti i tag html
        // selectedElm.set('content', e.replace(/<\/?[^>]+(>|$)/g, ''))
    }

    private setElementContentIntoTextEditor (element: any) {
        if (element) {
            const elementType = element.get('type')
            if (elementType === 'text' || elementType === 'fdlp-button') {
                this.resetTipTapEditorContent(element.get('content'))
            }
        }
    }

    private elementHasTextEditor (element: any) {
        const elementType = element.get('type')
        return element && (elementType === 'text' || elementType === 'fdlp-button')
    }

    private elementHasOptions (element: any) {
        const optionsEnabled = element.get('optionsEnabled')
        return optionsEnabled
    }

    private elementHasState (element: any) {
        const elementType = element.get('type')
        return elementType === 'fdlp-button'
    }

    private elementOpenAutomaticOptions (element: any) {
        const optionsEnabled = element.get('optionsEnabled')
        return optionsEnabled
    }

    private refreshCurrentComponent () {
        if (!this.currentSelected) return
        const selectedId = `#${this.currentSelected.getId()}`
        this.editor.select(null)
        this.editor.select(this.editor.DomComponents.getWrapper().find(selectedId)[0])
    }
}
