













import { Component, Prop, Vue } from 'vue-property-decorator'
import FdlpClassToggle from './../../FdlpClassToggle/Index.vue'
@Component({
    name: 'FdlpWrapperOptions',
    components: {
        FdlpClassToggle,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) element!: any
    get containerClasses () {
        return [
            { class: 'container', icon: 'fullscreen-exit', text: this.$t('landingpages.grapesjsOptionsComponents.fdlpWrapper.container') },
            { class: 'container-fluid', icon: 'fullscreen', text: this.$t('landingpages.grapesjsOptionsComponents.fdlpWrapper.containerFluid') },
        ]
    }
}
