import { Mark } from 'tiptap'
import { markInputRule, updateMark, removeMark } from 'tiptap-commands'

/**
 * FontSize - Extends the built in Mark from tiptap allowing
 * the editor to <drumroll> set the font-size.
 */
export class FontSize extends Mark {
    get name (): string {
        return 'fontSize'
    }

    get schema (): any {
        return {
            attrs: {
                fontSize: {
                    default: '',
                },
            },
            parseDOM: [
                {
                    style: 'font-size',
                    getAttrs: (value: string) => {
                        return value ? { fontSize: value } : ''
                    },
                },
            ],
            toDOM: (mark: any) => ['span', { style: `font-size: ${mark.attrs.fontSize}` }, 0],
        }
    }

    commands ({ type }: any): any {
        return (attrs: any) => {
            if ((attrs.fontSize)) {
                return updateMark(type, attrs)
            }
            return removeMark(type)
        }
    }

    inputRules ({ type }: any): any[] {
        return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)]
    }
}
