/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    prefix,
} from './../consts'
import {
    isUndefined,
    isFunction,
    isObject,
    isBoolean,
    isString,
    result,
} from 'underscore'

export default (
    editor: any,
    config: any,
) => {
    const dc = editor.DomComponents
    const { openTextEditorFunction = false } = config
    const imageType = dc.getType('image')
    const textType = dc.getType('text')
    const mapType = dc.getType('map')
    const videoType = dc.getType('video')
    const defaultType = dc.getType('default')
    const defaultModel = defaultType.model
    const defaultView = defaultType.view
    const defaultTypeDefaults = defaultModel.prototype.defaults

    dc.addType(`${prefix}header`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'HEADER' && el.classList.contains(`${prefix}header`)
        // },
        model: {
            ...defaultModel,
            defaults: {
                ...defaultTypeDefaults,
                name: 'Header',
                tagName: 'header',
                type: `${prefix}header`,
                removable: true,
                draggable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                selectable: true,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: false,
                layerable: true,
                void: false,
                traits: [],
            },
            // esempio di come inserire una regola css
            // init () {
            //     const editor: any = this.em.get('Editor')
            //     editor.CssComposer.setRule(
            //         `.${prefix}header .${prefix}inner-text h1`,
            //         {
            //             'margin-top': '15px',
            //         },
            //         {
            //             atRuleType: 'media',
            //             atRuleParams: '(max-width: 576px)',
            //         },
            //     )
            // },
        },
    })

    dc.addType(`${prefix}footer`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'FOOTER' && el.classList.contains(`${prefix}footer`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Footer',
                tagName: 'footer',
                type: `${prefix}footer`,
                removable: true,
                draggable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                selectable: true,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: false,
                layerable: true,
                void: false,
                traits: [],
            },
        },
    })

    dc.addType('image', {
        model: {
            ...imageType.model,
            defaults: {
                name: 'Immagine',
                resizable: true,
                editable: true,
                draggable: true,
                highlightable: true,
                copyable: false,
                void: true,
                optionsEnabled: true,
                traits: [
                    {
                        name: 'title',
                        label: 'Titolo',
                        placeholder: ' ',
                    },
                    {
                        name: 'data-href',
                        label: 'Link',
                        placeholder: ' ',
                    },
                    {
                        name: 'data-target',
                        label: 'Apertura link',
                        type: 'select',
                        options: [
                            { id: '_blank', name: 'Nuova Finestra' },
                            { id: '_self', name: 'Stessa Finestra' },
                        ],
                    },
                    {
                        name: 'src',
                        label: 'Sorgente',
                        placeholder: ' ',
                    },
                ],
                classes: ['mx-auto'],
                src: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+',
                fallback: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImZpbGw6IHJnYmEoMCwwLDAsMC4xNSk7IHRyYW5zZm9ybTogc2NhbGUoMC43NSkiPgogICAgICAgIDxwYXRoIGQ9Ik04LjUgMTMuNWwyLjUgMyAzLjUtNC41IDQuNSA2SDVtMTYgMVY1YTIgMiAwIDAgMC0yLTJINWMtMS4xIDAtMiAuOS0yIDJ2MTRjMCAxLjEuOSAyIDIgMmgxNGMxLjEgMCAyLS45IDItMnoiPjwvcGF0aD4KICAgICAgPC9zdmc+',
                stylable: [
                    'width',
                    'height',
                    'border',
                    'border-width',
                    'border-style',
                    'border-color',
                    'margin-top',
                    'margin-left',
                    'margin-right',
                    'margin-bottom',
                    'padding-top',
                    'padding-left',
                    'padding-right',
                    'padding-bottom',
                ],
            },
            init () {
                this.on(`change:attributes:src`, this.setNewSrc)
            },
            setNewSrc (a: string, srcValue: any) {
                this.set('src', srcValue)
            },
            // isFallbackSrc () {
            //     return this.get('src') === result(this, 'defaults').fallback
            // },
            initToolbar (...args: any) {
                defaultModel.prototype.initToolbar.apply(this, args)
                const em = this.em

                if (em) {
                    const cmd = em.get('Commands')
                    const cmdName = 'open-assets'

                    // Add Image Editor button only if the default command exists
                    if (cmd.has(cmdName)) {
                        let hasButtonBool = false
                        const tb = this.get('toolbar')

                        for (let i = 0; i < tb.length; i++) {
                            if (tb[i].command === cmdName) {
                                hasButtonBool = true
                                break
                            }
                        }

                        if (!hasButtonBool) {
                            tb.unshift({
                                attributes: { class: 'fa mdi mdi-pencil' },
                                command: cmdName,
                            })
                            this.set('toolbar', tb)
                        }
                    }
                }
            },
        },
        view: {
            ...imageType.view,
            initialize (o: any) {
                const model = this.model
                // eslint-disable-next-line prefer-rest-params
                defaultView.prototype.initialize.apply(this, arguments)
                this.listenTo(model, 'change:src', this.updateSrc)
                this.classEmpty = `${prefix}plh-image`
                const config = this.config
                config.modal && (this.modal = config.modal)
                config.am && (this.am = config.am)
                this.fetchFile()
            },
            // updateSrc () {
            //     const { model, classEmpty } = this
            //     const src = model.getSrcResult()
            //     const srcExists = src && !model.isDefaultSrc() && !model.isFallbackSrc()
            //     if (!src) {
            //         model.addAttributes({ src: model.defaults.fallback })
            //     } else {
            //         model.addAttributes({ src })
            //     }
            //     model[srcExists ? 'removeClass' : 'addClass'](classEmpty)
            //     // if (srcExists) {
            //     //     model.removeClass(classEmpty)
            //     // } else {
            //     //     model.addClass(classEmpty)
            //     // }
            // },
        },
    })

    dc.addType('map', {
        model: {
            ...mapType.model,
            defaults: {
                name: 'Mappa',
                resizable: false,
                editable: true,
                draggable: true,
                highlightable: true,
                stylable: [
                    'width',
                    'height',
                    'border',
                    'border-width',
                    'border-style',
                    'border-color',
                    'margin-top',
                    'margin-left',
                    'margin-right',
                    'margin-bottom',
                    'padding-top',
                    'padding-left',
                    'padding-right',
                    'padding-bottom',
                ],
                classes: ['map-iframe'],
                copyable: true,
                void: false,
                optionsEnabled: true,
            },
        },
    })

    dc.addType(`video`, {
        model: {
            ...videoType.model,
            defaults: {
                ...videoType.model.prototype.defaults,
                name: 'Video',
                resizable: false,
                editable: true,
                draggable: true,
                highlightable: true,
                copyable: true,
                void: false,
                optionsEnabled: true,
                stylable: [
                    'width',
                    'height',
                    'border',
                    'border-width',
                    'border-style',
                    'border-color',
                    'margin-top',
                    'margin-left',
                    'margin-right',
                    'margin-bottom',
                    // 'padding-top',
                    // 'padding-left',
                    // 'padding-right',
                    // 'padding-bottom',
                ],
                classes: ['videoWrapper', 'ratio-16-9'],
            },
            getProviderTrait () {
                const yt = 'yt'
                const vi = 'vi'
                return {
                    type: 'select',
                    label: 'Provider',
                    name: 'provider',
                    changeProp: 1,
                    options: [
                        { value: 'so', name: 'HTML5' },
                        { value: yt, name: 'Youtube' },
                        { value: vi, name: 'Vimeo' },
                    ],
                }
            },
            getVimeoTraits () {
                return [
                    this.getProviderTrait(),
                    {
                        label: 'Video ID',
                        name: 'videoId',
                        placeholder: 'eg. 123456789',
                        changeProp: 1,
                    },
                    this.getAutoplayTrait(),
                    this.getLoopTrait(),
                ]
            },
            getYoutubeTraits () {
                return [
                    this.getProviderTrait(),
                    {
                        label: 'Video ID',
                        name: 'videoId',
                        placeholder: 'es. jNQXAC9IVRw',
                        changeProp: 1,
                    },
                    this.getAutoplayTrait(),
                    this.getLoopTrait(),
                    this.getControlsTrait(),
                    {
                        type: 'checkbox',
                        label: 'Relazionati',
                        name: 'rel',
                        changeProp: 1,
                    },
                    {
                        type: 'checkbox',
                        label: 'Modest',
                        name: 'modestbranding',
                        changeProp: 1,
                    },
                ]
            },
            getSourceTraits () {
                return [
                    this.getProviderTrait(),
                    {
                        label: 'Sorgente',
                        name: 'src',
                        placeholder: 'es. ./media/video.mp4',
                        changeProp: 1,
                    },
                    {
                        label: 'Anteprima',
                        name: 'poster',
                        placeholder: 'es. ./media/image.jpg',
                        // changeProp: 1
                    },
                    this.getAutoplayTrait(),
                    this.getLoopTrait(),
                    this.getControlsTrait(),
                ]
            },
            getControlsTrait () {
                return {
                    type: 'checkbox',
                    label: 'Controlli',
                    name: 'controls',
                    changeProp: 1,
                }
            },
            toHTML (opts: any = {}) {
                const attrs = []
                const classAndIdattrs = []
                const customTag = opts.tag
                const tag = customTag || this.get('tagName')
                const sTag = this.get('void')
                const customAttr = opts.attributes
                let attributes = this.getAttrToHTML()
                delete opts.tag

                // Get custom attributes if requested
                if (customAttr) {
                    if (isFunction(customAttr)) {
                        attributes = customAttr(this, attributes) || {}
                    } else if (isObject(customAttr)) {
                        attributes = customAttr
                    }
                }

                for (const attr in attributes) {
                    const val = attributes[attr]
                    const value = isString(val) ? val.replace(/"/g, '&quot;') : val

                    if (attr === 'id' || attr === 'class') {
                        classAndIdattrs.push(`${attr}="${value}"`)
                        continue
                    }

                    if (!isUndefined(value)) {
                        if (isBoolean(value)) {
                            value && attrs.push(attr)
                        } else {
                            attrs.push(`${attr}="${value}"`)
                        }
                    }
                }

                const attrString = attrs.length ? ` ${attrs.join(' ')}` : ''
                const classAndIdattrsString = classAndIdattrs.length ? ` ${classAndIdattrs.join(' ')}` : ''
                let code = `<div${classAndIdattrsString}><${tag}${attrString}${sTag ? '/' : ''}>${this.get('content')}`
                this.get('components').each((comp: any) => (code += comp.toHTML(opts)))
                !sTag && (code += `</${tag}></div>`)

                return code
            },
        },
        view: {
            ...videoType.view,
            initVideoEl (el: any) {
                el.className = this.ppfx + 'no-pointer'
            },
        },
    })

    const textSizeTraitName = 'tagName'
    dc.addType('text', {
        // isComponent: (el: any) => {
        //     if (el && ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(el.tagName)) {
        //         return { type: 'text', [textSizeTraitName]: el.tagName.toLowerCase() }
        //     }
        // },
        model: {
            ...textType.model,
            defaults: {
                name: 'Testo',
                editable: false,
                unstylable: ['align-items', 'box-shadow'],
                showCustomTextEditor: true,
                optionsEnabled: true,
                traits: [{
                    type: 'select',
                    options: [
                        { value: 'p', name: 'Paragrafo' },
                        { value: 'h1', name: 'Titolo 1' },
                        { value: 'h2', name: 'Titolo 2' },
                        { value: 'h3', name: 'Titolo 3' },
                        { value: 'h4', name: 'Titolo 4' },
                        { value: 'h5', name: 'Titolo 5' },
                        { value: 'h6', name: 'Titolo 6' },
                    ],
                    label: 'Tipologia testo',
                    name: textSizeTraitName,
                    changeProp: 1,
                }],
                [textSizeTraitName]: 'p',
            },
            tagUpdated () {
                const editor: any = this.em.get('Editor')
                editor.select(null)
                const coll = this.collection
                const at = coll.indexOf(this)
                const styles = this.getStyle()
                coll.remove(this)
                this.setStyle(styles)
                coll.add(this, { at })
                editor.select(this)
            },
        },
        view: {
            ...textType.view,
            events: {
                click: 'openTextEditor',
            },
            onRender () {
                if (openTextEditorFunction) {
                    const target = this.model
                    const content = target.get('content')
                    openTextEditorFunction(content)
                }
            },
            openTextEditor () {
                if (openTextEditorFunction) {
                    const target = this.model
                    const content = target.get('content')
                    openTextEditorFunction(content)
                }
            },
        },
    })

    dc.addType(`${prefix}quote`, {
        model: {
            ...textType.model,
            type: `${prefix}quote`,
            defaults: {
                name: 'Citazione',
                tagName: 'blockquote',
                editable: false,
                unstylable: ['align-items'],
                showCustomTextEditor: true,
                optionsEnabled: true,
                traits: [],
            },
        },
        view: {
            ...textType.view,
            events: {
                click: 'openTextEditor',
            },
            onRender () {
                if (openTextEditorFunction) {
                    const target = this.model
                    const content = target.get('content')
                    openTextEditorFunction(content)
                }
            },
            openTextEditor () {
                if (openTextEditorFunction) {
                    const target = this.model
                    const content = target.get('content')
                    openTextEditorFunction(content)
                }
            },
        },
    })

    // dc.addType('textnode', {
    //     isComponent: (el: any) => {
    //         // console.log('iscomponent', el, el.getAttribute('data-gjs-type') === `textnode`)
    //         return null
    //     },
    // })

    dc.addType('svg', {
        model: {
            defaults: {
                removable: false,
                draggable: false,
                copyable: false,
                badgable: false,
                stylable: false,
                selectable: false,
                droppable: false,
                highlightable: false,
                hoverable: false,
                resizable: false,
                editable: false,
                layerable: false,
                void: false,
            },
        },
    })

    // sm.add(`${prefix}link`, {
    //     type: 1,
    //     label: `${prefix}link`,
    //     private: true,
    // })

    dc.addType(`link`, {
        isComponent: () => {
            return null
        },
        model: {
            defaults: {
                name: 'Link',
                tagName: 'a',
                type: `link`,
                removable: false,
                draggable: false,
                copyable: false,
                badgable: false,
                stylable: false,
                selectable: false,
                droppable: false,
                highlightable: false,
                hoverable: false,
                resizable: false,
                editable: false,
                layerable: false,
                void: false,
                traits: [{
                    name: 'href',
                    label: 'Link',
                }, {
                    name: 'target',
                    label: 'Apertura link',
                    type: 'select',
                    options: [
                        { id: '_blank', name: 'Nuova Finestra' },
                        { id: '_self', name: 'Stessa Finestra' },
                    ],
                }],
            },
        },
    })

    // sm.add('${prefix}button', {
    //     type: 1,
    //     label: '${prefix}button',
    //     private: true,
    // })

    dc.addType(`${prefix}button`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'BUTTON'
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Bottone',
                tagName: 'a',
                type: `${prefix}button`,
                removable: true,
                draggable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                selectable: true,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: true,
                layerable: true,
                showCustomTextEditor: true,
                optionsEnabled: true,
                void: false,
                traits: [{
                    name: 'href',
                    label: 'Link',
                }, {
                    name: 'target',
                    label: 'Apertura link',
                    type: 'select',
                    options: [
                        { id: '_blank', name: 'Altra Finestra' },
                        { id: '_self', name: 'Stessa Finestra' },
                    ],
                }, {
                    name: 'data-lp-tracking',
                    label: 'Abilita tracciamento',
                    type: 'checkbox',
                    valueTrue: 'true',
                    valueFalse: 'false',
                }, {
                    name: 'data-lp-tracking-name',
                    label: 'Nome evento',
                }],
            },
        },
        view: {
            ...defaultType.view,
            events: {
                click: 'openTextEditor',
            },
            openTextEditor () {
                if (openTextEditorFunction) {
                    const target = this.model
                    const content = target.get('content')
                    openTextEditorFunction(content)
                }
            },
        },
    })

    // sm.add(`${prefix}row`, {
    //     type: 1,
    //     label: `${prefix}row`,
    //     private: true,
    // })

    dc.addType(`${prefix}row`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'DIV' && el.classList.contains(`${prefix}row`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Box',
                tagName: 'div',
                type: `${prefix}row`,
                removable: true,
                draggable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: ['box-shadow'],
                selectable: true,
                droppable: `[data-gjs-type=${prefix}col]`,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: true,
                layerable: true,
                void: false,
                traits: [],
            },
        },
    })

    // sm.add(`${prefix}placeholder`, {
    //     type: 1,
    //     label: `${prefix}placeholder`,
    //     private: true,
    // })

    dc.addType(`${prefix}textimage`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'div' && el.classList.contains(`${prefix}textimage`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Immagine + Testo',
                tagName: 'div',
                type: `${prefix}textimage`,
                removable: true,
                draggable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'color', 'font-family', 'box-shadow'],
                selectable: true,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: true,
                layerable: true,
                void: false,
                optionsEnabled: true,
                traits: [],
            },
        },
    })

    dc.addType(`${prefix}placeholder`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'DIV' && el.classList.contains(`${prefix}placeholder`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Placeholder',
                tagName: 'div',
                type: `${prefix}placeholder`,
                removable: false,
                draggable: false,
                copyable: false,
                badgable: false,
                stylable: false,
                selectable: false,
                droppable: false,
                highlightable: false,
                hoverable: false,
                resizable: false,
                editable: false,
                layerable: false,
                void: false,
            },
            toHTML () {
                return ''
            },
        },
    })

    dc.addType(`${prefix}col`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'DIV' && el.classList.contains(`${prefix}col`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Colonna',
                tagName: 'div',
                type: `${prefix}col`,
                removable: true,
                copyable: true,
                badgable: true,
                stylable: true,
                unstylable: [
                    'align-items',
                    'width',
                    'margin-right',
                    'margin-left',
                    'box-shadow',
                ],
                selectable: true,
                draggable: `[data-gjs-type=${prefix}row]`,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: true,
                layerable: true,
                void: false,
                traits: [],
            },
        },
    })

    dc.addType(`${prefix}inner-col`, {
        // isComponent: (el: any) => {
        //     return el.tagName === 'DIV' && el.classList.contains(`${prefix}col`)
        // },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Contenuto colonna',
                tagName: 'div',
                type: `${prefix}inner-col`,
                removable: false,
                copyable: false,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                selectable: true,
                draggable: false,
                droppable: `
                    [data-gjs-type=image],
                    [data-gjs-type=text],
                    [data-gjs-type=${prefix}quote],
                    [data-gjs-type=${prefix}button],
                    [data-gjs-type=${prefix}fog-embedded],
                    [data-gjs-type=${prefix}textimage],
                    [data-gjs-type=video],
                    [data-gjs-type=map],
                    [data-gjs-type=${prefix}countdown]
                `,
                highlightable: true,
                hoverable: true,
                resizable: false,
                editable: true,
                layerable: true,
                void: false,
                traits: [],
            },
        },
    })

    dc.addType(`${prefix}wrapper`, {
        isComponent: (el: any) => {
            return el.tagName === 'DIV' && el.getAttribute('data-gjs-type') === `${prefix}wrapper`
        },
        model: {
            defaults: {
                ...defaultTypeDefaults,
                name: 'Contenitore',
                tagName: 'div',
                type: `${prefix}wrapper`,
                removable: false,
                draggable: false,
                copyable: false,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                droppable: `[data-gjs-type=${prefix}footer], [data-gjs-type=${prefix}row], [data-gjs-type=${prefix}header]`,
                selectable: true,
                resizable: false,
                highlightable: true,
                layerable: true,
                hoverable: true,
                void: false,
                optionsEnabled: true,
                style: {
                    width: '100%',
                    'min-height': '100%',
                    padding: '15px',
                },
                traits: [],
            },
        },
    })

    // questi sono override del componente body
    dc.addType(`wrapper`, {
        model: {
            defaults: {
                badgable: true,
                stylable: [
                    'background',
                    'background-color',
                    'background-image',
                    'background-linear-gradient',
                    'background-repeat',
                    'background-attachment',
                    'background-position',
                    'background-size',
                    'color',
                    'font-family',
                    '__bg-type',
                ],
                droppable: false,
                selectable: true,
                highlightable: true,
                hoverable: true,
            },
        },
    })

    dc.addType(`${prefix}countdown`, {
        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                name: 'Countdown',
                startfrom: '',
                droppable: false,
                draggable: true,
                removable: true,
                copyable: false,
                badgable: true,
                stylable: true,
                unstylable: ['align-items', 'box-shadow'],
                highlightable: true,
                layerable: true,
                hoverable: true,
                optionsEnabled: true,
                traits: [{
                    label: 'Fine',
                    name: 'startfrom',
                    changeProp: 1,
                    type: 'date',
                }, {
                    label: 'Modalità editor',
                    name: 'data-show-block',
                    default: 'yes',
                    type: 'checkbox',
                    valueTrue: 'yes',
                    valueFalse: 'no',
                }],
                script: function () {
                    const startfrom = '{[ startfrom ]}'
                    const countDownDate = new Date(startfrom).getTime()
                    const isEditorBlock = this.getAttribute('data-gjs-type')
                    const countdownEl = this.querySelector('[data-js=countdown]')
                    const endTextEl = this.querySelector('[data-js=countdown-endtext]')
                    const dayEl = this.querySelector('[data-js=countdown-day]')
                    const hourEl = this.querySelector('[data-js=countdown-hour]')
                    const minuteEl = this.querySelector('[data-js=countdown-minute]')
                    const secondEl = this.querySelector('[data-js=countdown-second]')
                    const oldInterval = this.gjs_countdown_interval
                    let newInterval: any = null
                    if (oldInterval) {
                        oldInterval && clearInterval(oldInterval)
                    }

                    const setTimer = (days: any, hours: any, minutes: any, seconds: any) => {
                        dayEl.innerHTML = days < 10 ? '0' + days : days
                        hourEl.innerHTML = hours < 10 ? '0' + hours : hours
                        minuteEl.innerHTML = minutes < 10 ? '0' + minutes : minutes
                        secondEl.innerHTML = seconds < 10 ? '0' + seconds : seconds
                    }

                    const moveTimer = () => {
                        const showBlocks = this.getAttribute('data-show-block')
                        const now = new Date().getTime()
                        const distance = countDownDate - now
                        const days = Math.floor(distance / 86400000)
                        const hours = Math.floor((distance % 86400000) / 3600000)
                        const minutes = Math.floor((distance % 3600000) / 60000)
                        const seconds = Math.floor((distance % 60000) / 1000)

                        setTimer(days, hours, minutes, seconds)

                        /* If the count down is finished, write some text */
                        if (distance < 0) {
                            clearInterval(newInterval)
                            // endTextEl.innerHTML = endTxt
                            countdownEl.style.display = showBlocks === 'yes' && isEditorBlock ? 'block' : 'none'
                            endTextEl.style.display = 'block'
                            setTimer(0, 0, 0, 0)
                        } else {
                            countdownEl.style.display = 'block'
                            endTextEl.style.display = showBlocks === 'yes' && isEditorBlock ? 'block' : 'none'
                        }
                    }

                    const showBlocks = this.getAttribute('data-show-block')
                    if (countDownDate) {
                        newInterval = setInterval(moveTimer, 1000)
                        this.gjs_countdown_interval = newInterval
                        endTextEl.style.display = showBlocks === 'yes' && isEditorBlock ? 'block' : 'none'
                        countdownEl.style.display = 'block'
                        moveTimer()
                    } else {
                        setTimer(0, 0, 0, 0)
                        countdownEl.style.display = 'block'
                        endTextEl.style.display = showBlocks === 'yes' && isEditorBlock ? 'block' : 'none'
                    }
                },
            },
        }, {
            isComponent (el: any) {
                if (el.getAttribute &&
                    el.getAttribute('data-gjs-type') === `${prefix}countdown`) {
                    return {
                        type: `${prefix}countdown`,
                    }
                }
            },
        }),

        view: defaultView.extend({
            init () {
                this.listenTo(this.model, 'change:startfrom change:attributes:data-show-block', this.updateScript)
                const comps = this.model.get('components')
                const labelDays = 'Giorni'
                const labelHours = 'Ore'
                const labelMinutes = 'Minuti'
                const labelSeconds = 'Secondi'
                // Add a basic countdown template if it's not yet initialized
                if (!comps.length) {
                    comps.reset()
                    comps.add([
                        {
                            tagName: 'div',
                            classes: [`${prefix}countdown-cont`],
                            attributes: { 'data-js': 'countdown' },
                            components: [
                                {
                                    tagName: 'div',
                                    classes: [`${prefix}countdown-block`],
                                    components: [
                                        {
                                            tagName: 'div',
                                            content: '00',
                                            name: 'Giorni',
                                            attributes: { 'data-js': 'countdown-day' },
                                            classes: [`${prefix}countdown-digit`],
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                            stylable: true,
                                            unstylable: ['align-items', 'text-align', 'box-shadow'],
                                        },
                                        {
                                            type: `text`,
                                            tagName: 'p',
                                            content: labelDays,
                                            classes: [`${prefix}countdown-label`],
                                            draggable: false,
                                            copyable: false,
                                            removable: false,
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                        },
                                    ],
                                },
                                {
                                    tagName: 'div',
                                    classes: [`${prefix}countdown-block`],
                                    components: [
                                        {
                                            tagName: 'div',
                                            content: '00',
                                            name: 'Ore',
                                            attributes: { 'data-js': 'countdown-hour' },
                                            classes: [`${prefix}countdown-digit`],
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                            stylable: true,
                                            unstylable: ['align-items', 'text-align', 'box-shadow'],
                                        },
                                        {
                                            type: `text`,
                                            tagName: 'p',
                                            content: labelHours,
                                            classes: [`${prefix}countdown-label`],
                                            draggable: false,
                                            copyable: false,
                                            removable: false,
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                        },
                                    ],
                                },
                                {
                                    tagName: 'div',
                                    classes: [`${prefix}countdown-block`],
                                    components: [
                                        {
                                            tagName: 'div',
                                            content: '00',
                                            name: 'Minuti',
                                            attributes: { 'data-js': 'countdown-minute' },
                                            classes: [`${prefix}countdown-digit`],
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                            stylable: true,
                                            unstylable: ['align-items', 'text-align', 'box-shadow'],
                                        },
                                        {
                                            type: `text`,
                                            tagName: 'p',
                                            content: labelMinutes,
                                            classes: [`${prefix}countdown-label`],
                                            draggable: false,
                                            copyable: false,
                                            removable: false,
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                        },
                                    ],
                                },
                                {
                                    tagName: 'div',
                                    classes: [`${prefix}countdown-block`],
                                    components: [
                                        {
                                            tagName: 'div',
                                            content: '00',
                                            name: 'Secondi',
                                            attributes: { 'data-js': 'countdown-second' },
                                            classes: [`${prefix}countdown-digit`],
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                            stylable: true,
                                            unstylable: ['align-items', 'text-align', 'box-shadow'],
                                        },
                                        {
                                            type: `text`,
                                            tagName: 'p',
                                            content: labelSeconds,
                                            classes: [`${prefix}countdown-label`],
                                            draggable: false,
                                            copyable: false,
                                            removable: false,
                                            selectable: true,
                                            highlightable: true,
                                            hoverable: true,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            tagName: 'div',
                            classes: [`${prefix}countdown-endtext`],
                            selectable: true,
                            highlightable: true,
                            hoverable: true,
                            stylable: true,
                            attributes: { 'data-js': 'countdown-endtext' },
                            components: [
                                {
                                    type: `text`,
                                    tagName: 'h2',
                                    content: 'Testo da mostrare al termine del countdown',
                                    draggable: false,
                                    copyable: false,
                                    removable: false,
                                    selectable: true,
                                    highlightable: true,
                                    hoverable: true,
                                },
                            ],
                        },
                    ])
                }
            },
        }),
    })

    dc.addType('default', {
        model: {
            // The `defaults` property is handled differently
            // and will be merged with the old `defaults`
            defaults: {
                removable: false, // Can't remove it
                draggable: false, // Can't move it
                copyable: false, // Disable copy/past
                badgable: false,
                stylable: false,
                selectable: false,
                droppable: false,
                highlightable: false,
                showCustomTextEditor: false,
                hoverable: false,
                // Indicates if it's possible to resize the component (at the moment implemented only on Image Components)
                // It's also possible to pass an object as options for the Resizer
                resizable: false,
                // Allow to edit the content of the component (used on Text components)
                editable: false,
                // Hide the component inside Layers
                layerable: false,
                // This property is used by the HTML exporter as void elements do not
                // have closing tag, eg. <br/>, <hr/>, etc.
                void: false,
                traits: [],
            },
        },
    })
}
