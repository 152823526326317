import { Link } from 'tiptap-extensions'
export class CustomLink extends Link {
    get schema () {
        return {
            attrs: {
                href: {
                    default: null,
                },
                target: {
                    default: null,
                },
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: 'a[href]',
                    getAttrs: (dom: any) => ({
                        href: dom.getAttribute('href'),
                        target: dom.getAttribute('target'),
                    }),
                },
            ],
            toDOM: (node: any) => ['a', {
                ...node.attrs,
                target: '__blank',
                rel: 'noopener noreferrer nofollow',
            }, 0],
        }
    }
}
