import { IProject } from './projects.types'
export enum PageStatus {
    enabled = 'enabled',
    disabled = 'disabled',
}

export interface IPageIntegrations {
    leadplusGroupId?: string
}
export interface IPage {
    _id: string
    name: string
    title: string
    description: string
    projectId: string
    project?: IProject
    status: PageStatus
    publishedAt: string
    favicon: File | string | null
    slug: string
    socialTitle: string
    socialDescription: string
    ogImage: string
    twitterImage: string
    userId: string
    subdomain: string
    integrations?: IPageIntegrations
    createdAt: Date
    updatedAt: Date
}
