



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { difference } from 'lodash'
@Component({
    name: 'FdlpClassToggle',
})
export default class extends Vue {
    @Prop({ required: true, default: null }) element!: any
    @Prop({ required: true, default: null }) containerClasses!: any[]

    private elementClasses: string[] = []

    mounted () {
        if (!this.element) {
            console.log('non c\'è l\'elemento')
        } else {
            this.elementClasses = this.element.getClasses()
        }
    }

    private setClass (className: string) {
        const containerClassesArr = []
        for (let i = 0; i < this.containerClasses.length; i++) {
            containerClassesArr.push(this.containerClasses[i].class)
        }
        const classesToSet = difference(this.elementClasses, containerClassesArr)
        classesToSet.push(className)
        this.element.setClass(classesToSet)
        this.elementClasses = classesToSet
        this.$emit('change', {
            el: this.element,
            className,
        })
    }

    private isInElementClasses (className: string) {
        const val = this.elementClasses.indexOf(className) > -1
        return val
    }
}
