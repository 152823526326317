import {
    keyLeadplus,
    commandNameLeadplus,
    typeLeadplus,
} from '../consts'

export default (editor: any, opts: any = {}) => {
    const dc = editor.DomComponents
    const defaultType = dc.getType('default')
    const defaultModel = defaultType.model

    editor.SelectorManager.add('fogEmbedded', {
        type: 1,
        label: 'fogForm',
        private: true,
    })

    editor.SelectorManager.add('fdlp-fog-embedded', {
        type: 1,
        label: 'fdlp-fog-embedded',
        private: true,
    })

    dc.addType(typeLeadplus, {
        model: defaultModel.extend({
            defaults: {
                ...defaultModel.prototype.defaults,
                name: 'Form e Popup Embedded',
                editable: false,
                copyable: false,
                removable: true,
                draggable: true,
                badgable: true,
                pageId: opts.pageId,
                leadplusUrl: opts.leadplusRenderScript,
                stylable: [
                    'background-color',
                    'margin-top',
                    'margin-left',
                    'margin-right',
                    'margin-bottom',
                    'padding-top',
                    'padding-left',
                    'padding-right',
                    'padding-bottom',
                ],
                selectable: true,
                droppable: false,
                highlightable: true,
                hoverable: true,
                resizable: false,
                void: false,
                traits: [{
                    label: 'ID Form',
                    name: 'data-form-id',
                }],
                classes: ['fdlp-fog-embedded'],
                script: function () {
                    const isEditorBlock = this.getAttribute('data-gjs-type')
                    const dataFormId = this.getAttribute('data-form-id')
                    const pageId = '{[ pageId ]}'
                    const leadplusUrl = '{[ leadplusUrl ]}'
                    if (!isEditorBlock) {
                        // eslint-disable-next-line
                        (function(i:any,s:any,o:any,g:any,r:any,a?:any,m?:any){i['LeadplusObject']=r;if(i[r])return;i[r]=function(){if(!i[r].q)i[r].q=[];i[r].q.push(arguments)};i[r].l=1*(new Date as unknown as number);a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,"script",leadplusUrl,'rf')
                        // eslint-disable-next-line
                        window.rf(dataFormId, `LandingpageApi.Pages.${pageId}`)
                    }
                },
                ...opts.propsLeadplus,
            },
            /**
             * Initilize the component
             */
            init () {
                this.listenTo(this, `change:${keyLeadplus}`, this.onLeadplusChange)
            },

            /**
             * Callback to launch on keyLeadplus change
             */
            onLeadplusChange () {
                this.components(this.get(keyLeadplus))
            },
            initToolbar (...args: any) {
                defaultModel.prototype.initToolbar.apply(this, args)
                const cmdName = commandNameLeadplus
                const tb = this.get('toolbar')
                tb.unshift({
                    attributes: { class: 'fa mdi mdi-pencil' },
                    command: cmdName,
                })
                this.set('toolbar', tb)
            },
        }, {
            isComponent (el: any) {
                if (el.getAttribute &&
                    el.getAttribute('data-gjs-type') === typeLeadplus) {
                    return {
                        type: typeLeadplus,
                    }
                }
            },
        }),
        view: defaultType.view.extend({
            events: {
                dblclick: 'onActive',
            },

            onActive () {
                const target = this.model
                this.em.get('Commands').run(commandNameLeadplus, { target })
            },
            init () {
                this.listenTo(this.model, 'change:attributes:data-form-id', this.updateScript)
            },
        }),
    })
}
