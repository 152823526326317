import grapesjs from 'grapesjs'
import commands from './commands'

export default grapesjs.plugins.add('gjs-custom-gallery', (editor: any, opts: any = {}) => {
    const config: any = opts
    const defaults: any = {}

    // Load defaults
    for (const name in defaults) {
        if (!(name in config)) {
            config[name] = defaults[name]
        }
    }
    // Load commands
    commands(editor)
})
