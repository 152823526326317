import { serviceLandingPage } from '@/utils/request'
import { CreateProjectDto, UpdateProjectDto } from './dto/projects.dto'

export const getProjects = async (cancelToken?: any) =>
    serviceLandingPage.get('/projects', { cancelToken })

export const getProjectById = async (id: string, cancelToken?: any) =>
    serviceLandingPage.get(`/projects/${id}`, { cancelToken })

export const createProject = async (data: CreateProjectDto, cancelToken?: any) =>
    serviceLandingPage.post('/projects', data, { cancelToken })

export const updateProject = (data: UpdateProjectDto, id: string, cancelToken?: any) =>
    serviceLandingPage.put(`/projects/${id}`, data, { cancelToken })

export const deleteProject = (id: string, cancelToken?: any) =>
    serviceLandingPage.delete(`/projects/${id}`, { cancelToken })
