import { serviceLandingPage } from '@/utils/request'
import { CreatePageDto, PreviewLivePageDto, UpdatePageDto, PatchPageDto } from './dto/pages.dto'
import { AxiosResponse } from 'axios'
import { IPage } from './types/pages.types'
import { IPaginated } from './types/paginated.types'

export const getPages = async (params: any, signal?: AbortSignal): Promise<AxiosResponse<IPaginated<IPage>>> =>
    serviceLandingPage.get('/pages', {
        params,
        // signal,
    })

export const getPageById = async (id: string, params?: any, cancelToken?: any): Promise<AxiosResponse<IPage>> =>
    serviceLandingPage.get(`/pages/${id}`, { params, cancelToken })

export const createPage = (data: CreatePageDto, cancelToken?: any): Promise<AxiosResponse<IPage>> =>
    serviceLandingPage.post(`/pages`, data, { cancelToken })

export const checkCreatePage = (cancelToken?: any): Promise<AxiosResponse<IPage>> =>
    serviceLandingPage.head(`/pages`, { cancelToken })

export const checkPublishPage = (cancelToken?: any): Promise<AxiosResponse<IPage>> =>
    serviceLandingPage.head(`/pages/publish`, { cancelToken })

export const previewLive = (data: PreviewLivePageDto, cancelToken?: any) =>
    serviceLandingPage.post(`/pages/preview/live`, data, { cancelToken })

export const preview = (id: string, cancelToken?: any) =>
    serviceLandingPage.get(`/pages/${id}/preview`, { cancelToken })

export const updatePage = (data: UpdatePageDto, id: string, cancelToken?: any) =>
    serviceLandingPage.put(`/pages/${id}`, data, { cancelToken })

export const changeStatusPage = (data: UpdatePageDto, id: string, cancelToken?: any) =>
    serviceLandingPage.patch(`/pages/${id}/change-status`, data, { cancelToken })

export const patchPage = (data: PatchPageDto, id: string, cancelToken?: any) =>
    serviceLandingPage.patch(`/pages/${id}`, data, { cancelToken })

export const deletePage = (id: string, cancelToken?: any) =>
    serviceLandingPage.delete(`/pages/${id}`, { cancelToken })

export const duplicatePage = (id: string, cancelToken?: any) =>
    serviceLandingPage.post(`/pages/${id}/duplicate`, {}, { cancelToken })

export const sendPagePreview = (emails: string[], id: string, cancelToken?: any) =>
    serviceLandingPage.post(`/pages/${id}/sent-email`, { emails }, { cancelToken })
