import {
    prefix,
} from './../consts'

export default function (editor: any, opt: any = {}): any {
    const c = opt.blocksBasicOpts
    const bm = editor.BlockManager
    const blocks = opt.blocks

    // aggiungere a questo elenco se vengono aggiunte delle classi per stilare ed identificare i blocchi creati
    const privateCls = [
        `.${prefix}button`,
        `.${prefix}col`,
        `.${prefix}inner-col`,
        `.${prefix}row`,
        `.${prefix}header`,
        `.${prefix}textimage`,
        `.${prefix}logo`,
        `.${prefix}inner-text`,
        `.${prefix}footer`,
        `.${prefix}placeholder`,
        `.${prefix}countdown-digit`,
        `.${prefix}countdown-label`,
        `${prefix}plh-image`,
        '.map-iframe',
        `.col`,
        `.col-sm`,
        `.col-sm-2`,
        `.col-sm-3`,
        `.col-sm-4`,
        `.col-sm-5`,
        `.col-sm-6`,
        `.col-sm-7`,
        `.col-sm-8`,
        `.col-sm-9`,
        `.col-sm-10`,
        `.mr-auto`,
        `.mx-auto`,
        `.ml-auto`,
        `.flex-row-reverse`,
        `.${prefix}has-bg-image`,
        `.row`,
        `.videoWrapper`,
        `.ratio-16-9`,
        `.ratio-4-3`,
        `.free-ratio`,
        `.container`,
        `.container-fluid`,
    ]
    editor.on('selector:add', (selector: any) =>
        privateCls.indexOf(selector.getFullName()) >= 0 && selector.set('private', 1))

    const toAdd = (name: any) => blocks.indexOf(name) >= 0

    toAdd('text') && bm.add('text', {
        label: c.labelText,
        category: 'element',
        content: {
            type: 'text',
            tagName: 'p',
            content: 'Inserisci il tuo testo qui',
            style: {
                'min-height': '24px',
            },
            activeOnRender: 1,
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><g id="Raggruppa_46" data-name="Raggruppa 46"><path id="Unione_52" data-name="Unione 52" class="cls-1" d="M11.5,54.4V49.9H43v4.5Zm0-9v-4.5h45v4.5Zm0-9v-4.5h45v4.5Zm0-9V23H43v4.5Zm0-9V13.9h45v4.5Z"/></g></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd('image') && bm.add('image', {
        label: c.labelImage,
        category: 'element',
        content: {
            style: { display: 'block', 'max-width': '100%' },
            type: 'image',
            activeOnRender: 1,
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><path id="Unione_4" data-name="Unione 4" class="cls-1" d="M9.81,60.1V13.9H58.19V60.1ZM14,55.92h39.9V18.16H14Zm1.5-2.31L25,37.89l10.83,5.93,6.62-3L52.62,53.61ZM38.87,30A5.15,5.15,0,1,1,44.11,35H44A5.11,5.11,0,0,1,38.87,30Z"/></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}textimage`) && bm.add(`${prefix}textimage`, {
        label: c.labelTextImage,
        category: 'element',
        content: {
            type: `${prefix}textimage`,
            classes: [`${prefix}textimage`],
            components: [
                {
                    type: 'image',
                    draggable: false,
                    copyable: false,
                    removable: false,
                    selectable: true,
                    highlightable: true,
                    hoverable: true,
                },
                {
                    type: `text`,
                    tagName: 'p',
                    classes: [`textimagetext`],
                    content: 'Inserisci il tuo testo qui',
                    draggable: false,
                    copyable: false,
                    removable: false,
                    selectable: true,
                    highlightable: true,
                    hoverable: true,
                },
                `<style>
                    .${prefix}textimage {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .${prefix}textimage img {
                        width: 250px;
                        max-width: 100%;
                        flex: none;
                        margin: 0px;
                    }
                    .${prefix}textimage .textimagetext {
                        flex: 1 1 auto;
                        margin-right: 5px;
                        margin-left: 5px;
                        margin-bottom: 0;
                    }
                    @media (max-width: 768px) {
                        .${prefix}textimage {
                            flex-wrap: wrap;
                        }
                    }
                </style>
            `],
        },
        render: (el: any) => {
            const { model } = el
            return `<div style="display: flex;"><svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><path id="Unione_4" data-name="Unione 4" class="cls-1" d="M9.81,60.1V13.9H58.19V60.1ZM14,55.92h39.9V18.16H14Zm1.5-2.31L25,37.89l10.83,5.93,6.62-3L52.62,53.61ZM38.87,30A5.15,5.15,0,1,1,44.11,35H44A5.11,5.11,0,0,1,38.87,30Z"/></svg><svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><g id="Raggruppa_46" data-name="Raggruppa 46"><path id="Unione_52" data-name="Unione 52" class="cls-1" d="M11.5,54.4V49.9H43v4.5Zm0-9v-4.5h45v4.5Zm0-9v-4.5h45v4.5Zm0-9V23H43v4.5Zm0-9V13.9h45v4.5Z"/></g></svg></div><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}button`) && bm.add(`${prefix}button`, {
        label: c.labelButton,
        category: 'element',
        content: {
            type: `${prefix}button`,
            content: 'BOTTONE',
            classes: [`${prefix}button`],
            components: [
                `<style>
                    .${prefix}button {
                        color: #FFFFFF;
                        background-color: #008AB7;
                        border: 1px solid #008AB7;
                        border-radius: 4px;
                        font-weight: 500;
                        letter-spacing: .0892857143em;
                        outline: 0;
                        position: relative;
                        text-decoration: none!important;
                        transition: all cubic-bezier(.4,0,.2,1) .28s;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        vertical-align: middle;
                        text-align: center;
                        min-width: 50px;
                        width: auto;
                        display: block;
                        margin: 15px auto;
                        padding: 10px 15px;
                        cursor: pointer;
                    }
                    .${prefix}button:hover {
                        background-color: #03a7dc;
                        color: #FFFFFF;
                        border: 1px solid #03a7dc;
                    }
                </style>`,
            ],
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><g id="Bottone-2"><path id="Unione_54" data-name="Unione 54" class="cls-1" d="M46.36,41.51l-5.16,3.6V31.84h-33V13.9H59.77V31.84H49.46l6.71,7.7L50,40.17l3.32,9-3.67,1.34Z"/><polygon class="cls-1" points="57.18 39.93 40.7 21.04 40.7 46.07 46.11 42.29 49.31 51.12 53.92 49.43 50.65 40.6 57.18 39.93"/></g></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    const colStyle = `
    .${prefix}col {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .${prefix}inner-col {
        min-height: 20px;
        height: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }`
    const colHtml = `
        <div class="${prefix}col col-sm" data-gjs-type="${prefix}col">
            <div class="${prefix}inner-col" data-gjs-type="${prefix}inner-col">
                <div data-gjs-type="${prefix}placeholder" class="${prefix}placeholder">
                    Inserisci qui il tuo contenuto
                </div>
            </div>
        </div>
    `

    toAdd(`${prefix}row`) && bm.add(`${prefix}row`, {
        label: c.labelRow,
        category: 'layout',
        content: `
            <div class="${prefix}row row" data-gjs-type="${prefix}row">
                ${colHtml}
            </div>
            <style>
                .${prefix}row {
                    min-height: 40px;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                ${colStyle}
            </style>
        `,
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><g id="Box-2"><path id="Unione_51" data-name="Unione 51" class="cls-1" d="M60.72,56.79h0Zm-53.5,0V13.9h53.5V56.79Zm4-4h45.5V17.9H11.22Zm30.88-4.9v-4h5.79V38.1h4v9.79Zm-26,0V38.1h4v5.8h5.8v4ZM47.89,32.52V26.79H42.1v-4h9.79v9.72Zm-31.79,0V22.8h9.8v4H20.11v5.72Z"/></g></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}col`) && bm.add(`${prefix}col`, {
        label: c.labelColumn,
        category: 'layout',
        content: `
            ${colHtml}
            <style>
                ${colStyle}
            </style>
        `,
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#575757;}</style></defs><g id="Colonna"><path id="Unione_55" data-name="Unione 55" class="cls-1" d="M44.76,55.13V13.9H60.17V55.13Zm-18.46,0V13.9H41.7V55.13Zm-18.47,0V13.9H23.24V55.13Z"/></g></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}header`) && bm.add(`${prefix}header`, {
        label: c.labelHeader,
        category: 'layout',
        content: {
            type: `${prefix}header`,
            classes: [`${prefix}header`, 'row '],
            tagName: 'header',
            components: [
                {
                    tagName: 'div',
                    classes: [`${prefix}logo`, 'col-sm-3'],
                    type: `${prefix}col`,
                    draggable: false,
                    copyable: false,
                    removable: false,
                    components: [
                        {
                            type: `${prefix}inner-col`,
                            components: [
                                {
                                    type: 'image',
                                    draggable: false,
                                    copyable: false,
                                    removable: false,
                                    selectable: true,
                                    highlightable: true,
                                    hoverable: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    tagName: 'div',
                    classes: [`${prefix}inner-text`, 'col-sm-9'],
                    type: `${prefix}col`,
                    draggable: false,
                    copyable: false,
                    removable: false,
                    components: [
                        {
                            type: `${prefix}inner-col`,
                            components: [
                                {
                                    type: `text`,
                                    tagName: 'h1',
                                    content: '<span style="display: block"><strong>Nome Azienda</strong></span>',
                                    draggable: false,
                                    copyable: false,
                                    removable: false,
                                    selectable: true,
                                    highlightable: true,
                                    hoverable: true,
                                },
                            ],
                        },
                    ],
                },
                `<style>
                    .${prefix}header {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        background-color: #f8f8f8;
                    }

                    .${prefix}header .${prefix}logo img {
                        max-height: 50px;
                        max-width: 100%;
                    }

                    .${prefix}header .${prefix}inner-text h1{
                        min-height: 20px;
                        margin: 0px;
                    }

                    @media (max-width: 576px) {
                        .${prefix}header .${prefix}inner-text h1{
                            margin-top: 15px;
                        }
                    }
                </style>`,
            ],
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#525252;}</style></defs><path id="Tracciato_29" data-name="Tracciato 29" class="cls-1" d="M8.05,13.9H60V31.55H8.05Zm0,23.53H60v5.88H8.05Zm0,17.64V49.19H60v5.88Z"/></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}footer`) && bm.add(`${prefix}footer`, {
        label: c.labelFooter,
        category: 'layout',
        content: {
            type: `${prefix}footer`,
            style: {
                'text-align': 'center',
                padding: '10px 15px',
                'background-color': '#f8f8f8',
            },
            classes: [`${prefix}footer`, 'row '],
            components: [
                {
                    tagName: 'div',
                    classes: ['col-sm'],
                    components: [
                        {
                            type: `text`,
                            tagName: 'h2',
                            content: '<span style="text-align: center; display: block"><strong><span style="font-size: 30px">Nome azienda</span></strong></span>',
                            draggable: false,
                            copyable: false,
                            removable: false,
                            selectable: true,
                            highlightable: true,
                            hoverable: true,
                            style: {
                                'margin-bottom': '8px',
                                color: '#212529',
                            },
                        },
                        {
                            type: `text`,
                            tagName: 'p',
                            content: `<span style="display: block;"><span style="font-size: 16px">P.IVA - Città - Indirizzo - Telefono - E-mail</span></span>`,
                            draggable: false,
                            copyable: false,
                            removable: false,
                            selectable: true,
                            highlightable: true,
                            hoverable: true,
                            style: {
                                'margin-bottom': '8px',
                                'margin-top': '10px',
                                color: '#212529',
                            },
                        },
                        {
                            type: `text`,
                            tagName: 'p',
                            content: `<span style="display: block;">
                                <em>
                                    <u>
                                        <span style="font-size: 14px">Cookie Policy</span>
                                    </u>
                                    <span style="font-size: 14px"> | </span>
                                    <u>
                                        <span style="font-size: 14px">Privacy Policy</span>
                                    </u>
                                </em>
                            </span>`,
                            draggable: false,
                            copyable: false,
                            removable: false,
                            selectable: true,
                            highlightable: true,
                            hoverable: true,
                            style: {
                                color: '#a6a6a6',
                            },
                        },
                    ],
                },
            ],
            activeOnRender: 1,
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68"><defs><style>.cls-1{fill:#525252;}</style></defs><path id="Tracciato_29" data-name="Tracciato 29" class="cls-1" d="M8.05,55.07H60V37.43H8.05Zm0-23.52H60V25.66H8.05Zm0-17.65v5.88H60V13.9Z"/></svg><div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd('video') && bm.add('video', {
        label: c.labelVideo,
        category: 'element',
        content: {
            type: 'video',
            src: 'img/video2.webm',
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 55.44V12.56H60.75V55.44H7.25ZM11.25 51.44H56.75V16.56H11.25V51.44ZM25.1 43.57V24.43L44.85 34L25.1 43.57Z" fill="#575757"/>
                </svg>
                <div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd('map') && bm.add('map', {
        label: c.labelMap,
        category: 'element',
        content: {
            type: 'map',
            style: {
                display: 'block',
                width: '100%',
                height: '350px',
            },
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.6666 13.5233C46.2171 16.0738 47.65 19.533 47.65 23.14C47.65 30.66 34.0499 49.53 34.0499 49.53C34.0499 49.53 20.45 30.66 20.45 23.14C20.45 19.533 21.8828 16.0738 24.4333 13.5233C26.9838 10.9728 30.443 9.53998 34.0499 9.53998C37.6569 9.53998 41.1161 10.9728 43.6666 13.5233ZM29.1859 19.7499C28.543 20.7119 28.2 21.843 28.2 23C28.1986 23.7686 28.349 24.5299 28.6426 25.2402C28.9361 25.9506 29.367 26.596 29.9104 27.1395C30.4539 27.683 31.0993 28.1138 31.8097 28.4074C32.52 28.7009 33.2813 28.8513 34.0499 28.85C35.207 28.85 36.338 28.5069 37.3 27.8641C38.2621 27.2213 39.0119 26.3076 39.4546 25.2387C39.8974 24.1697 40.0133 22.9935 39.7875 21.8587C39.5618 20.7239 39.0047 19.6815 38.1865 18.8634C37.3684 18.0453 36.326 17.4881 35.1912 17.2624C34.0564 17.0367 32.8802 17.1525 31.8113 17.5953C30.7423 18.0381 29.8287 18.7879 29.1859 19.7499ZM38.51 46.95H45.18L51.51 58.53H16.49L22.79 46.95H29.54L34.05 53.66L38.51 46.95Z" fill="#575757"/>
                </svg>
                <div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    toAdd(`${prefix}quote`) && bm.add(`${prefix}quote`, {
        label: c.labelQuote,
        category: 'element',
        content: {
            type: 'fdlp-quote',
            content: 'Inserisci il tuo testo qui',
            style: {
                'min-height': '24px',
                color: '#777',
                'font-size': '16px',
                'padding-top': '10px',
                'padding-left': '10px',
                'padding-right': '10px',
                'padding-bottom': '10px',
                'box-shadow': '-5px 5px 0px 0px #777777',
                'margin-left': '5px',
                'margin-bottom': '5px',
            },
            activeOnRender: 1,
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.6 48H48L53.59 36.8V20H36.8V36.8H45.2L39.6 48ZM17.2 48H25.6L31.2 36.8V20H14.41V36.8H22.8L17.2 48Z" fill="#575757"/>
                </svg>
                <div class="gjs-block-label">${model.get('label')}</div>`
        },
    })

    const countdownStyle = `<style>
        .${prefix}countdown {
            text-align: center;
        }

        .${prefix}countdown-block {
            display: inline-block;
            vertical-align: top;
            margin: 0 10px;
            padding: 10px;
        }

        .${prefix}countdown-digit {
            font-size: 5rem;
        }

        .${prefix}countdown-endtext {
            display: none;
        }

        .${prefix}countdown-cont {
            margin-right: auto;
            margin-left: auto;
        }

        .${prefix}countdown-block {
            display: inline-block;
        }
    </style>`

    toAdd(`${prefix}countdown`) && bm.add(`${prefix}countdown`, {
        label: c.labelCountdown,
        category: 'element',
        content: {
            type: `${prefix}countdown`,
            classes: [`${prefix}countdown`],
            tagName: 'header',
            components: [
                `${countdownStyle}`,
            ],
        },
        render: (el: any) => {
            const { model } = el
            return `<svg class="gjs-block-svg" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0886 16.1734C25.6144 13.8175 29.7596 12.5601 34.0001 12.5601C39.6863 12.5601 45.1397 14.8189 49.1604 18.8397C53.1812 22.8605 55.4401 28.3138 55.4401 34.0001C55.4401 38.2405 54.1826 42.3857 51.8268 45.9115C49.4709 49.4373 46.1224 52.1853 42.2048 53.808C38.2872 55.4308 33.9763 55.8554 29.8173 55.0281C25.6584 54.2008 21.8381 52.1589 18.8397 49.1604C15.8413 46.162 13.7993 42.3417 12.972 38.1828C12.1448 34.0238 12.5693 29.713 14.1921 25.7953C15.8148 21.8777 18.5629 18.5292 22.0886 16.1734ZM25.0109 47.4532C27.6717 49.2311 30.8 50.1801 34.0001 50.1801C38.2905 50.1774 42.4044 48.4719 45.4381 45.4381C48.4719 42.4044 50.1774 38.2904 50.1801 34.0001C50.1801 30.8 49.2311 27.6717 47.4532 25.0109C45.6754 22.3501 43.1484 20.2763 40.1919 19.0517C37.2354 17.8271 33.9821 17.5066 30.8435 18.131C27.7049 18.7553 24.8219 20.2963 22.5591 22.5591C20.2963 24.8219 18.7553 27.7049 18.131 30.8435C17.5067 33.9821 17.8271 37.2354 19.0517 40.1919C20.2763 43.1484 22.3502 45.6754 25.0109 47.4532ZM35.3502 24.78L39.4302 26.93L34.8002 35.74L39.4402 38.18L37.3002 42.27L28.5602 37.67L30.7002 33.58L30.7102 33.59L35.3502 24.78Z" fill="#575757"/>
                </svg>
                <div class="gjs-block-label">${model.get('label')}</div>`
        },
    })
}
