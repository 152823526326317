// Types for image background
import { typeBgKey } from './utils'

// const bgTypeStyle = 'style="max-height: 16px; display: block" viewBox="0 0 24 24"'

export const typeBg = {
    name: ' ',
    property: typeBgKey,
    type: 'radio',
    defaults: 'img',
    options: [
        { value: 'img', className: 'fa mdi mdi-image-outline' },
        // { value: 'color', className: 'fa mdi mdi-format-color-fill' },
        { value: 'grad', className: 'fa mdi mdi-gradient-horizontal' },
    ],
}

export const typeImage = {
    name: ' ',
    property: 'background-image',
    type: 'custom-file',
    // full: 1,
    functionName: 'url',
    defaults: 'none',
}

export const typeBgRepeat = {
    name: 'Ripetizione',
    property: 'background-repeat',
    type: 'select',
    defaults: 'repeat',
    options: [
        { value: 'repeat', name: 'ripeti' },
        { value: 'repeat-x', name: 'ripeti x' },
        { value: 'repeat-y', name: 'ripeti y' },
        { value: 'no-repeat', name: 'non ripetere' },
    ],
}

export const typeBgPos = {
    name: 'Posizione',
    property: 'background-position',
    type: 'select',
    defaults: 'left top',
    options: [
        { value: 'left top', name: 'alto a sinistra' },
        { value: 'left center', name: 'centrato a sinistra' },
        { value: 'left bottom', name: 'basso a sinistra' },
        { value: 'right top', name: 'alto a destra' },
        { value: 'right center', name: 'centrato a destra' },
        { value: 'right bottom', name: 'basso a destra' },
        { value: 'center top', name: 'alto e centrato' },
        { value: 'center center', name: 'centrato' },
        { value: 'center bottom', name: 'basso e centrato' },
    ],
}

export const typeBgAttach = {
    name: 'Ancoraggio',
    property: 'background-attachment',
    type: 'select',
    defaults: 'scroll',
    options: [
        { value: 'scroll', name: 'scroll' },
        { value: 'fixed', name: 'fisso' },
        { value: 'local', name: 'locale' },
    ],
}

export const typeBgSize = {
    name: 'Dimensione',
    property: 'background-size',
    type: 'select',
    defaults: 'auto',
    options: [
        { value: 'auto' },
        { value: 'cover' },
        { value: 'contain' },
    ],
}

// Linear color

export const typeColorLin = {
    name: ' ',
    property: 'background-image',
    type: 'color-linear',
    defaults: 'none',
    full: 1,
}

// Gradient type

export const typeGradient = {
    name: '&nbsp;',
    property: 'background-image',
    type: 'gradient',
    value: 'linear-gradient(90deg, #d983a6 0%, #713873 100%)',
    defaults: 'none',
    full: 1,
}
