













import { Component, Prop, Vue } from 'vue-property-decorator'
import FdlpClassToggle from './../../FdlpClassToggle/Index.vue'

@Component({
    name: 'FdlpTextimageOptions',
    components: {
        FdlpClassToggle,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) element!: any
    get containerClasses () {
        return [
            { class: '', icon: 'image-left', size: '7' },
            { class: 'flex-row-reverse', icon: 'image-right', size: '7' },
        ]
    }
}
