import { Node } from 'tiptap'
import { toggleBlockType } from 'tiptap-commands'

export class Paragraph extends Node {
    get name () {
        return 'paragraph'
    }

    get schema () {
        return {
            attrs: {
                textAlign: {
                    default: null,
                },
            },
            content: 'inline*',
            draggable: false,
            group: 'block',
            parseDOM: [
                {
                    tag: 'span',
                    getAttrs: (node: any) => {
                        return node?.style?.textAlign ? { textAlign: node.style.textAlign } : { textAlign: null }
                    },
                },
            ],
            toDOM (node: any) {
                if (node.attrs.textAlign) {
                    return ['span', { style: `text-align: ${node.attrs.textAlign}; display: block` }, 0]
                }
                return ['span', { style: `display: block;` }, 0]
            },

        }
    }

    commands ({ type, schema }: any) {
        return (attrs?: any) => {
            return toggleBlockType(type, schema.nodes.paragraph, attrs)
        }
    }
}
