import { Mark } from 'tiptap'
import { markInputRule, removeMark } from 'tiptap-commands'

export class FontTextColor extends Mark {
    get name () {
        return 'fontTextColor'
    }

    get schema () {
        return {
            attrs: {
                fontTextColor: {
                    default: '#000000',
                },
            },
            parseDOM: [{
                style: 'color',
                getAttrs: (mark: any) => mark ? { fontTextColor: mark } : '',
            }],
            toDOM: (mark: any) => ['span', { style: `color: ${mark.attrs.fontTextColor}` }, 0],
        }
    }

    commands ({ type }: any) {
        return (attrs: any) => {
            if (attrs.fontTextColor) {
                return this.customUpdateMark(type, attrs)
            }
            return removeMark(type)
        }
    }

    inputRules ({ type }: any) {
        return [
            markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type),
        ]
    }

    customUpdateMark (type: any, attrs: any) {
        return (state: any, dispatch: any) => {
            const { doc, selection, tr } = state
            const { empty, $cursor, ranges } = selection
            if ((empty && !$cursor) || !this.markApplies(doc, ranges, type)) return false
            if (dispatch) {
                if ($cursor) {
                    dispatch(tr.addStoredMark(type.create(attrs)))
                } else {
                    ranges.forEach((ref$1: any) => {
                        const { $to, $from } = ref$1

                        if (doc.rangeHasMark($from.pos, $to.pos, type)) {
                            tr.removeMark($from.pos, $to.pos, type)
                        }

                        tr.addMark($from.pos, $to.pos, type.create(attrs))
                    })
                    dispatch(tr.scrollIntoView())
                }
            }
            return true
        }
    }

    markApplies (doc: any, ranges: any, type: any) {
        for (let i = 0; i < ranges.length; i++) {
            const { $from, $to } = ranges[i]
            let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false
            doc.nodesBetween($from.pos, $to.pos, (node: any) => {
                if (can) return false
                can = node.inlineContent && node.type.allowsMarkType(type)
            })
            if (can) return true
        }
        return false
    }
}
