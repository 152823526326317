import {
    commandNameLeadplus,
} from '../consts'

export default (editor: any, config: any) => {
    const cm = editor.Commands
    cm.add(commandNameLeadplus, {
        run: (editor: any, sender: any, opts: any = {}) => {
            const leadplusTarget = opts.target || editor.getSelected()
            const leadplusTargetAttr = leadplusTarget.getAttributes()
            let leadplusForm = ''
            if (leadplusTargetAttr['data-form-id']) {
                leadplusForm = leadplusTargetAttr['data-form-id']
            }
            // options.openModalFunction(leadplusForm)
            config.openModalFunction(leadplusForm, leadplusTarget)
        },
    })
}
