














import { Component, Prop, Vue } from 'vue-property-decorator'
import FdlpClassToggle from './../../FdlpClassToggle/Index.vue'

@Component({
    name: 'VideoOptions',
    components: {
        FdlpClassToggle,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) element!: any
    get containerClasses () {
        return [
            { class: 'ratio-16-9', text: '16:9' },
            { class: 'ratio-4-3', text: '4:3' },
            { class: 'free-ratio', text: 'Auto' },
        ]
    }

    private setStyles (e: any) {
        console.log(e)
        if (e && e.className === 'free-ratio') {
            e.el.setStyle({ height: '300px' })
        } else {
            e.el.setStyle({ height: 'auto' })
        }
    }
}
