import openImport from './openImport'
import {
    cmdImport,
    cmdDeviceDesktop,
    cmdDeviceTablet,
    cmdDeviceMobile,
    cmdClear,
} from '../consts'

export default (editor: any, config: any) => {
    const cm = editor.Commands
    const txtConfirm = config.textCleanCanvas
    cm.add(cmdImport, openImport(editor, config))
    cm.add(cmdDeviceDesktop, (e: any) => e.setDevice('Desktop'))
    cm.add(cmdDeviceTablet, (e: any) => e.setDevice('Tablet'))
    cm.add(cmdDeviceMobile, (e: any) => e.setDevice('Mobile portrait'))
    cm.add(cmdClear, (e: any) => confirm(txtConfirm) && e.runCommand('core:canvas-clear'))
}
