import styleGradient from 'grapesjs-style-gradient'
import {
    prefix,
} from './../consts'
import * as styleTypesAll from './styleTypes'
import loadColorLinear from './colorLinear'
import { typeBgKey } from './utils'
export const hasBgImageClass = `${prefix}has-bg-image`
export default (editor: any, opts: any) => {
    const sm = editor.StyleManager
    const csm = opts.customStyleManager

    const handleBgImageClass = (hasImage: boolean) => {
        const selEl = editor.getSelected()
        if (!selEl) return
        if (hasImage) {
            selEl.addClass(hasBgImageClass)
        } else {
            selEl.removeClass(hasBgImageClass)
        }
    }

    sm.addType('custom-file', {
        create ({ props, change }: any) {
            const el = document.createElement('div')
            el.className = 'gjs-sm-field gjs-sm-file'
            el.innerHTML = `
            <div class="gjs-sm-label" data-sm-label="">
                <span class="gjs-sm-icon " title="">
                    Immagine
                </span>
            </div>
            <div style="display: flex">
                <div style="flex: auto;" class="gjs-fields" data-sm-fields="">
                    <div style="width: 100%;" class="gjs-field gjs-field-text">
                        <input type="hidden" placeholder="">
                        <input placeholder="Sorgente" type="text" value="${props.value && props.value !== 'none' ? props.value.replace(/'/g, '') : ''}" placeholder="" id="gjs-sm-image-text">
                    </div>
                </div>
                <div id="gjs-sm-input-holder">
                    <div class="gjs-sm-btn-c">
                        <button class="gjs-sm-btn" id="gjs-sm-images" type="button">
                            Galleria
                        </button>
                    </div>
                </div>
            </div>
            <div id="gjs-sm-preview-box" style="display: ${props?.value && props.value !== 'none' ? 'block' : 'none'};">
                <div id="gjs-sm-preview-file" style="background-image: url(${props.value ? props.value : ''});"></div>
                <div id="gjs-sm-close">⨯</div>
            </div>
            `
            const inputEl = el.querySelector('#gjs-sm-images')
            const textEl: any = el.querySelector('#gjs-sm-image-text')
            const closePreviewEl = el.querySelector('#gjs-sm-preview-box #gjs-sm-close')
            if (!inputEl || !textEl) return
            inputEl.addEventListener('click', () => this.openAssetsCommand(change)) // change will trigger the emit
            textEl.addEventListener('blur', (e: any) => {
                change({
                    event: {
                        detail: {
                            url: e.target.value,
                        },
                    },
                    complete: true,
                })
            })
            // change will trigger the emit
            if (closePreviewEl) {
                closePreviewEl.addEventListener('click', () => this.clearImage(change)) // change will trigger the emit
            }
            return el
        },
        emit ({ el, updateStyle }: any, { event, complete }: any) {
            const valueRes = event?.detail?.url || ''
            if (el) {
                const textBox = el.querySelector('#gjs-sm-image-text')
                const previewBox = el.querySelector('#gjs-sm-preview-box')
                const previewImg = el.querySelector('#gjs-sm-preview-file')
                if (valueRes && valueRes !== 'none') {
                    textBox.value = valueRes
                    previewBox.setAttribute('style', 'display: block;')
                    previewImg.setAttribute('style', `background-image: url(${valueRes});`)
                } else {
                    textBox.value = ''
                    previewBox.setAttribute('style', 'display: none;')
                    previewImg.setAttribute('style', `background-image: url("none");`)
                }
            }
            updateStyle(valueRes, { complete })
            if (valueRes) {
                handleBgImageClass(true)
            }
        },
        openAssetsCommand (change: any) {
            editor.Commands.run('open-assets', { change })
        },
        clearImage (change: any) {
            change({ event: false, complete: true })
        },
        destroy ({ el }: any) {
            if (!el) return
            el.querySelector('#gjs-sm-images').removeEventListener('click', this.openAssetsCommand)
            el.querySelector('#gjs-sm-preview-box #gjs-sm-close').removeEventListener('click', this.clearImage)
        },
    })

    const options = {
        ...{
            // Options for the `grapesjs-style-gradient` plugin
            styleGradientOpts: {
                inputDirection: {
                    name: 'Direzione',
                },
                inputType: {
                    name: 'Tipologia',
                },
            },

            // Extend single style property definition of the plugin.
            // You can this, for example, to change the defauld gradient color
            propExtender: (p: any) => p,

            // Use this function to change/add/extend style properties for each BG type
            typeProps: (p: any) => p,
        },
        ...opts,
    }

    let styleTypes: any = { ...styleTypesAll }
    const stack = sm.getType('stack')
    const propModel = stack.model
    styleTypes = Object.keys(styleTypes).reduce((acc: any, item: any) => {
        const prop = styleTypes[item]
        acc[item] = options.propExtender(prop) || prop
        return acc
    }, {})

    const getPropsByType = (type?: any) => {
        let result = [
            styleTypes.typeImage,
            styleTypes.typeBgRepeat,
            styleTypes.typeBgPos,
            styleTypes.typeBgAttach,
            styleTypes.typeBgSize,
        ]

        switch (type) {
            case 'color':
                result = [styleTypes.typeColorLin]
                break
            case 'grad':
                result = [styleTypes.typeGradient]
                break
        }

        return options.typeProps(result, type) || result
    }

    styleGradient(editor, {
        colorPicker: 'default',
        inputDirection: { property: '__gradient-direction' },
        inputType: { property: '__gradient-type' },
        ...options.styleGradientOpts,
    })

    loadColorLinear(editor, sm)

    sm.addType('bg', {
        model: propModel.extend({
            defaults: () => ({
                ...propModel.prototype.defaults,
                detached: 1,
                preview: 1,
                full: 1,
                prepend: 1,
                properties: [
                    styleTypes.typeBg,
                    ...getPropsByType(),
                ],
            }),

            init () {
                this.handleTypeChange = this.handleTypeChange.bind(this)
                this.listenTo(this.getLayers(), 'add', this.onNewLayerAdd)
            },

            _updateLayerProps (layer: any, type: any) {
                const props = layer.get('properties')
                props.remove(props.filter((it: any, id: any) => id !== 0))
                getPropsByType(type).forEach((item: any) => props.push(item))
            },

            /**
             * On new added layer we should listen to filter_type change
             * @param  {Layer} layer
             */
            onNewLayerAdd (layer: any) {
                const typeProp = layer.getPropertyAt(0)
                layer.listenTo(typeProp, 'change:value', this.handleTypeChange)
            },

            handleTypeChange (propType: any, type: any, opts: any) {
                const currLayer = this.getCurrentLayer()
                currLayer && this._updateLayerProps(currLayer, type)
                opts.fromInput && this.trigger('updateValue')
            },

            getLayersFromTarget (target: any, { resultValue }: any = {}) {
                const layers: any[] = []
                const layerValues = resultValue || target.getStyle()[this.get('property')]
                const types = layerValues[typeBgKey]
                let hasImage = false
                if (types) {
                    this.splitValues(types).forEach((type: any, idx: any) => {
                        const props = getPropsByType(type)
                        layers.push({
                            properties: [
                                { ...styleTypes.typeBg, value: type },
                                ...props.map((prop: any) => {
                                    const values = this.splitValues(layerValues[prop.property])
                                    let value = values[idx]

                                    if (prop.type === 'color-linear') {
                                        const parsedValue = this.parseValue(value, { complete: 1 })
                                        value = this.splitValues(parsedValue.value)[0]
                                    } else if (prop.type === 'custom-file') {
                                        value = value && this.parseValue(value, { complete: 1 }).value
                                        hasImage = true
                                    }

                                    return {
                                        ...prop,
                                        ...value && { value },
                                    }
                                }),
                            ],
                        })
                    })
                }
                handleBgImageClass(hasImage)
                return layers
            },
        }),
        view: stack.view,
    })

    sm.getSectors().reset(csm && csm.length ? csm : [])
}
