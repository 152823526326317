













import { Component, Prop, Vue } from 'vue-property-decorator'
import FdlpClassToggle from './../../FdlpClassToggle/Index.vue'

@Component({
    name: 'ImageOptions',
    components: {
        FdlpClassToggle,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) element!: any
    get containerClasses () {
        return [
            { class: 'mr-auto', icon: 'horizontal-align-left' },
            { class: 'mx-auto', icon: 'horizontal-align-center' },
            { class: 'ml-auto', icon: 'horizontal-align-right' },
        ]
    }
}
