











































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import {
    Editor,
    EditorContent,
    EditorMenuBar,
    // Extension,
} from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Strike,
    Underline,
    History,
    Placeholder,
} from 'tiptap-extensions'

import {
    FontSize,
} from './tools/FontSize'

import {
    CustomLink,
} from './tools/CustomLink'

import {
    Paragraph,
} from './tools/Paragraph'

import {
    FontTextColor,
} from './tools/FontTextColor'
import VsListItem from '@/components/VsListItem/Index.vue'

@Component({
    name: 'TipTapContent',
    components: {
        EditorContent,
        EditorMenuBar,
        VsSeparator,
        VsListItem,
    },
})
export default class extends Vue {
    @Prop({ default: '' }) private content?: string
    @Prop({ default: null }) private currentSelected!: any
    @Prop({ default: false }) private disabled!: boolean
    @Prop({ default: '' }) private placeholder!: string
    @Prop({ default: '' }) private error!: string
    private editable = false
    private showHeading = false
    private selectSize = 'paragraph'
    editor: any = null
    private linkUrl: any = null
    private linkMenuIsActive = false
    private textColorPickerIsActive = false
    private textColor = '#000000'

    private getSelectedFontColor (attrs: any) {
        return attrs && attrs.fontTextColor ? attrs.fontTextColor : '#000000'
    }

    mounted (): void {
        if (!this.editor) this.initEditor()
    }

    beforeDestroy (): void {
        if (this.editor !== null) {
            this.editor.destroy()
        }
    }

    private initEditor () {
        this.editor = new Editor({
            autoFocus: false,
            editable: true,
            extensions: [
                new Blockquote(),
                new BulletList(),
                new CodeBlock(),
                new HardBreak(),
                // new class extends Extension {
                //     keys () {
                //         return {
                //             Enter (state: any, dispatch: any, view: any) {
                //                 const { schema, tr } = view.state
                //                 const hardBreak = schema.nodes.hard_break
                //                 const transaction = tr.replaceSelectionWith(hardBreak.create()).scrollIntoView()
                //                 view.dispatch(transaction)
                //                 return true
                //             },
                //         }
                //     }
                // }(),
                new Paragraph(),
                new Heading({ levels: [1, 2, 3] }),
                new HorizontalRule(),
                new ListItem(),
                new OrderedList(),
                new TodoItem(),
                new TodoList(),
                new CustomLink(),
                new Bold(),
                new Code(),
                new Italic(),
                new Strike(),
                new Underline(),
                new History(),
                new Placeholder({
                    emptyNodeClass: 'is-editor-empty',
                    emptyNodeText: this.placeholder,
                    showOnlyWhenEditable: false,
                }),
                new FontSize(),
                new FontTextColor(),
            ],
            onUpdate: (updateFunctions: { getJSON: any, getHTML: any }) => {
                const text = updateFunctions.getHTML() // per rimuovere un tag specifico => .replace(/<\/?[p>]+(>|$)/g, '')
                this.$emit('update-text', text)
            },
            editorProps: {
                transformPastedHTML: (html: any) => {
                    return html.innerText()
                },
            },
        })
    }

    private clearContent (): void {
        this.editor.clearContent(true)
        this.editor.focus()
    }

    public resetContent (val?: any): void {
        if (!this.editor) this.initEditor()
        this.editor.setContent(val)
        this.editor.focus()
    }

    private showLinkMenu (attrs: any): void {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
    }

    private showTextColorPicker (attrs: any): void {
        this.textColor = attrs.fontTextColor
        this.textColorPickerIsActive = true
    }

    private hideLinkMenu () {
        this.linkUrl = null
        this.linkMenuIsActive = false
    }

    private setLinkUrl (command: any, url: any, closePopover: any) {
        command({ href: url, target: '_blank' })
        closePopover()
        this.hideLinkMenu()
    }

    private changeTextColor (color: string) {
        this.textColor = color
    }

    private saveTextColor (command: any, color: string, closePopover: any) {
        command({ fontTextColor: color })
        closePopover()
        this.textColorPickerIsActive = false
    }
}
