import grapesjs from 'grapesjs'
import commands from './commands'
import blocks from './blocks'
import components from './components'

export default grapesjs.plugins.add('gjs-leadplus-without-groups', (editor: any, opts: any = {}) => {
    const config: any = opts
    const defaults: any = {
        category: 'Elementi Base',
        blockLabel: 'FORM E POPUP',
        blockTitle: 'FORM E POPUP',
        blockLeadplus: {},
        propsLeadplus: {},
        pageId: '',
        buttonLabel: 'Salva',
        openModalFunction: () => {
            console.log('leadplus openModalFunction not implemented')
        },
    }

    // Load defaults
    for (const name in defaults) {
        if (!(name in config)) {
            config[name] = defaults[name]
        }
    }

    // Load components (types)
    components(editor, config)
    // Load blocks
    blocks(editor, config)
    // Load commands
    commands(editor, config)
})
