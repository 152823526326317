











import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'

@Component({
    name: 'VsListItem',
    components: {
        VsLink,
    },
})

export default class extends Vue {
    @Prop({
        required: false,
        default: null,
    }) to?: any

    @Prop({
        required: false,
        default: '',
    }) url?: string

    get listItemClasses () {
        return {
            'vs-single-list-item': true,
            'vs-cursor-pointer': true,
            'vs-body-medium': true,
            'vs-px-4': true,
            'vs-text-grey-900': true,
            // 'vs-py-2': true,
            'hover:vs-bg-grey-200': true,
        }
    }

    get componentType () {
        if (this.url || this.to) return 'vs-link'
        return 'button'
    }

    get outerDynamicProps () {
        if (this.url) {
            return {
                href: this.url,
            }
        }
        if (this.to) {
            return {
                to: this.to,
            }
        }
        return {}
    }
}
